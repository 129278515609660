import { ReferenceField, TextField, useRecordContext } from "react-admin";
import { React } from "react";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

const AliasOrBarcodeReference = ({ source, reference }) => {
  const record = useRecordContext();

  const isAComposite =
    record?.physicalCompositeID !== undefined &&
    record?.physicalCompositeID !== null;

  return isAComposite ? (
    <Tooltip
      title={
        <p
          style={{
            fontSize: "1.8em",
          }}
        >
          Physical Composite
        </p>
      }
    >
      <Typography component="span" noWrap={true} variant="inherit">
        {record.barcode}
      </Typography>
    </Tooltip>
  ) : (
    <ReferenceField source={source} reference={reference} link="show">
      <CustomDisplayAliasOrBarcode />
    </ReferenceField>
  );
};

const CustomDisplayAliasOrBarcode = () => {
  const record = useRecordContext();

  return record.alias === "" ? (
    <TextField source="barcode" />
  ) : (
    <Tooltip
      title={
        <p
          style={{
            fontSize: "1.8em",
          }}
        >
          Barcode: {record.barcode}
        </p>
      }
    >
      {/* I had to add this div to make tooltip working
        known issue: https://github.com/marmelab/react-admin/issues/4349 */}
      <div>
        <TextField source="alias" />
      </div>
    </Tooltip>
  );
};

AliasOrBarcodeReference.defaultProps = {
  label: "Barrel",
  addLabel: true,
};
AliasOrBarcodeReference.propTypes = {
  source: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
};

export { AliasOrBarcodeReference };
