import moment from "moment";
import * as d3 from "d3";
import { RenderTooltipText } from "./tooltip";
import { GetLotList } from "./lotDropdown";
import { bwColours } from "../../lib/theme";

const bwScheme = [
  bwColours.primary.red,
  bwColours.primary.beige,
  bwColours.primary.offblack,
  bwColours.secondary.pink,
  bwColours.secondary.orange,
  bwColours.secondary.lightBlue,
  bwColours.secondary.darkBlue,
  bwColours.accent.purple,
  bwColours.accent.green,
  bwColours.accent.blue,
  bwColours.accent.brown,
  bwColours.accent.pink,
  bwColours.accent.red,
  bwColours.accent.teal,
  bwColours.accent.mauve,
  bwColours.accent.army,
  bwColours.accent.orange,
];

const ChangeTimeFormat = (timeSelected) => {
  // Convert a string selected from the dropdown to a datetime format use a cut-off
  var cutOff = moment().format();

  switch (timeSelected) {
    case "1 Day":
      cutOff = moment().subtract(1, "day").toDate();
      break;
    case "1 Week":
      cutOff = moment().subtract(1, "week").toDate();
      break;
    case "2 Weeks":
      cutOff = moment().subtract(2, "weeks").toDate();
      break;
    case "1 Month":
      cutOff = moment().subtract(1, "month").toDate();
      break;
    case "1 Year":
      cutOff = moment().subtract(1, "year").toDate();
      break;
    case "Auto":
      /*FIXME: this is an arbitrary length of time. The API request requires time to be specified now, but since we don't know
      the earliest date, we just pull the last 3 years of data and figure out the min date when we get the response*/
      cutOff = moment().subtract(3, "year").toDate();
      break;
    default:
    // required for ESLint: https://eslint.org/docs/latest/rules/default-case
  }
  return cutOff;
};

const GetYMax = (data) => {
  const yMax = Math.max(
    ...data.map((element) => {
      return element.fso2;
    })
  );

  if (yMax === null || yMax === -Infinity) {
    // if between these two dates, there is no measurements,
    // return 40 as a standard height
    return 40;
  }

  return yMax;
};

const GetYMaxBetweenDates = (data, lowerBound, upperBound) => {
  // Get the max fso2 between two dates, which will be used as a y axis parameter
  const filtered = data.filter(function (d) {
    return d.datetime >= lowerBound && d.datetime <= upperBound;
  });

  return GetYMax(filtered);
};

function ExtractMinMaxDate(inputArray) {
  const recordMinDate = new Date(
    Math.min(
      ...inputArray.map((element) => {
        return new Date(element.datetime);
      })
    )
  );

  const recordMaxDate = new Date(
    Math.max(
      ...inputArray.map((element) => {
        return new Date(element.datetime);
      })
    )
  );
  return [recordMinDate, recordMaxDate];
}

function FilterDataByDate(min, max, array) {
  if (!min || !max || !array) {
    return null;
  }

  const filteredData = array.filter((item) => {
    const itemDate = new Date(item.datetime);
    return itemDate >= min && itemDate <= max;
  });

  return filteredData;
}

function SetCanvas(ref, styles) {
  let svg = d3
    .select(ref)
    .append("svg")
    .attr("id", "canvas")
    .style("height", styles.height)
    .style("width", styles.width);

  // Define clipping path
  svg
    .append("defs")
    .append("clipPath")
    .attr("id", "clip")
    .append("rect")
    .attr("x", svgSize.marginleft)
    .attr("width", svgSize.width - svgSize.marginleft)
    .attr("height", svgSize.height - svgSize.margintop - svgSize.marginbottom);

  return svg;
}

const svgSize = {
  width: 940,
  height: 400,
  margintop: 0,
  marginbottom: 30,
  marginleft: 30,
};

function SetScales(windowMinDate, windowMaxDate, yMax) {
  let xScale = d3
    .scaleTime()
    .domain([windowMinDate, windowMaxDate])
    .range([svgSize.marginleft, svgSize.width - svgSize.marginleft])
    .nice();

  let yScale = d3
    .scaleLinear()
    .domain([-3, yMax + 5])
    .range([svgSize.height - svgSize.marginbottom, svgSize.margintop]);

  return { xScale: xScale, yScale: yScale };
}

function SetAxesToCanvas(canvas, scales) {
  let xAxis = d3.axisBottom().scale(scales.xScale);
  // Add x-axis to the canvas
  canvas
    .append("g")
    .attr("class", "x-axis")
    .attr(
      "transform",
      "translate(0," + (svgSize.height - svgSize.marginbottom) + ")"
    )
    .call(xAxis);

  // Add scale to Y axis
  let yAxis = d3.axisLeft().scale(scales.yScale);
  canvas
    .append("g")
    .attr("class", "y-axis")
    .attr(
      "transform",
      "translate(" + svgSize.marginleft + "," + svgSize.margintop + ")"
    )
    .call(yAxis);
}

function SetPointsToCanvas(canvas, data, scales, tooltip) {
  var mouseover = function () {
    tooltip.style("opacity", 1);
  };
  var mousemove = function (d, i) {
    const text = RenderTooltipText(i);

    tooltip
      .html(text)
      .style("left", d3.pointer(this)[0] + 200 + "px")
      .style("top", d3.pointer(this)[1] + "px");
  };
  var mouseleave = function () {
    tooltip.style("opacity", 0);
  };

  var color = d3.scaleOrdinal(bwScheme);

  const lotList = GetLotList(data);

  canvas
    .selectAll("circle")
    .data(data)
    .enter()
    .append("circle")
    .attr("cx", function (d) {
      return scales.xScale(d.datetime);
    })
    .attr("cy", function (d) {
      return scales.yScale(parseFloat(d.fso2));
    })
    .attr("r", 5)
    .style("fill", function (d) {
      if (lotList.length > 34) {
        return bwColours.primary.red;
      } else {
        return color(d.lot);
      }
    })
    .style("opacity", 0.5)
    .attr("clip-path", "url(#clip)")
    .on("mouseover", mouseover)
    .on("mousemove", mousemove)
    .on("mouseleave", mouseleave);
}

export {
  ChangeTimeFormat,
  GetYMaxBetweenDates,
  ExtractMinMaxDate,
  FilterDataByDate,
  SetCanvas,
  SetScales,
  SetAxesToCanvas,
  GetYMax,
  SetPointsToCanvas,
};
