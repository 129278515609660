import { useRecordContext } from "react-admin";
import PropTypes from "prop-types";
import { lighten, darken } from "@mui/material";

const ContextTagField = ({ source }) => {
  const record = useRecordContext();
  if (!record) return null;

  let textToDisplay;
  if (source === "type" && record[source] === "virtual composite") {
    textToDisplay = record.context;
  } else if (source === "type" && record[source] === "lot composite") {
    textToDisplay = record.context;
  } else if (source === "type" && record[source] === "fso2") {
    textToDisplay = "fSO\u2082";
  } else {
    textToDisplay = record[source];
  }

  let color = "#464042";
  switch (textToDisplay) {
    case "before addition":
      color = "#730519";
      break;
    case "spotcheck after add":
      color = "#105F73";
      break;
    case "lot addition":
      color = "#00A87B";
      break;
    case "addition":
      color = "#00A87B";
      break;
    case "fSO\u2082":
      color = "#464042";
      break;
    default:
      break;
  }

  const style = {
    paddingTop: 2,
    paddingBottom: 1,
    paddingLeft: 3,
    paddingRight: 3,

    borderStyle: "solid",
    borderWidth: 1,
    borderColor: lighten(color, 0.82),
    borderRadius: 4,

    color: darken(color, 0),
    background: lighten(color, 0.93),
  };

  return textToDisplay !== "" ? (
    <span style={style}>{textToDisplay}</span>
  ) : null;
};

ContextTagField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};
ContextTagField.defaultProps = {
  label: "Context",
  addLabel: true,
};

export { ContextTagField };
