import React from "react";
import AuthPage from "./authPage";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import { Alert } from "@mui/material";

const CustomerSelectionPage = ({ custs }) => (
  <AuthPage>
    <div style={{ minHeight: "34vh" }}>
      <h3>Select Winery</h3>
      {custs && custs.length > 0 ? (
        <List component="nav">
          {custs.map((c) => (
            <ListItem
              button
              divider
              key={c.id}
              component={Link}
              to={"/c/" + c.customerID + "/"}
            >
              <ListItemText primary={c.customerLongName} />
            </ListItem>
          ))}
        </List>
      ) : (
        <Alert
          severity="error"
          style={{
            marginBottom: "5em",
            textAlign: "left",
          }}
        >
          <b>
            Your account is not associated
            <br />
            with any wineries.
          </b>
          <br />
          <br />
          Please contact
          <br />
          <a href="mailto:support@barrelwise.ca">support@barrelwise.ca</a>.
        </Alert>
      )}
    </div>
  </AuthPage>
);

export default CustomerSelectionPage;
