import * as d3 from "d3";
import moment from "moment";

function SetTooltip(ref) {
  let tooltip = d3
    .select(ref)
    .append("div")
    .style("opacity", 1)
    .attr("id", "tooltip")
    .attr("class", "tooltip")
    .style("height", "100px")
    .style("width", "300px");

  return tooltip;
}

function RenderTooltipText(i) {
  const fso2 = i.fso2;
  const lot = i.lot;
  const barrel = i.alias === "" ? i.barrel : i.alias;
  const datetime = moment(i.datetime).format("llll");

  const text =
    "FSO\u2082: <b>" +
    fso2.toFixed(0) +
    " ppm" +
    "</b><br/>Lot: <b>" +
    lot +
    "</b><br/>Barrel: <b>" +
    barrel +
    "</b><br/>Time: <b>" +
    datetime +
    "</b>";

  return text;
}

export { SetTooltip, RenderTooltipText };
