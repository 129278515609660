const resource = "events/fso2_reading";

function cleanDataFetched(rawArray) {
  if (rawArray === undefined) {
    return;
  }
  const d = rawArray.map((x) => {
    return {
      id: x.id,
      datetime: new Date(x.datetime),
      fso2: x.fso2,
      lot: x.lotName === null ? "No Lot" : x.lotName,
      barrel: x.barcode,
      alias: x.alias,
    };
  });

  return d;
}

const FetchData = async (
  dataProvider,
  setData,
  setLoading,
  setError,
  apiFilter
) => {
  try {
    setLoading(true);
    setError(null);

    // Make API request using the dataProvider
    const { data } = await dataProvider.getList(resource, {
      pagination: {},
      sort: {},
      filter: apiFilter,
    });

    setData(cleanDataFetched(data));
    setLoading(false);
  } catch (error) {
    setError(error);
    setLoading(false);
  }
};

export { FetchData };
