import { React, useState } from "react";

import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  DateTimeInput,
  ReferenceInput,
  Edit,
  Create,
  Show,
  EditButton,
  Pagination,
  NumberInput,
  ReferenceField,
  NumberField,
  number,
  minValue,
  ReferenceManyField,
  useRecordContext,
  required,
  useListContext,
  Button,
  TopToolbar,
  FilterButton,
  CreateButton,
  AutocompleteInput,
  SelectInput,
  BooleanField,
  TabbedShowLayout,
  Tab,
} from "react-admin";

// Lib
import { custInfo } from "../lib/customer";
import { hasScope, SCOPE } from "../lib/auth";
import { DetailsButton } from "../lib/detailsButton";
import { WineColourField, WineColorInput } from "../lib/wineColour";
import { VolumeField } from "../lib/volume";
import {
  CreateCSVDownloadQueryParams,
  CreateCSVDownloadURL,
} from "../lib/csvDownload";
import { OpenURL } from "../lib/csvDownload";

//MUI
import IconDownload from "@mui/icons-material//CloudDownload";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconCancel from "@mui/icons-material//Cancel";
import DialogActions from "@mui/material/DialogActions";
import { CustomDateField } from "../lib/customDatetimeField";

import { LotNameField } from "./lots";
import { CustomTab } from "../lib/customTab";

const validateYear = [number(), minValue(2000)];

const BlendAppBarTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span> {"Blend " + record.name}</span>;
};

const archivedFilterChoices = [
  { id: false, name: "Hide archived" },
  { id: true, name: "Show only archived" },
];

const blendFilters = [
  <TextInput label="Blend" source="name" alwaysOn resettable />,
  <SelectInput
    source="archived"
    label="Archived"
    alwaysOn
    choices={archivedFilterChoices}
    emptyText="Show all"
  />,
];

const BlendExportButton = () => {
  const [showDialog, setShowDialog] = useState(false);
  const { sort, filterValues } = useListContext();

  const handleDownload = () => {
    setShowDialog(false);
    const queryParams = CreateCSVDownloadQueryParams(sort, filterValues);
    const csvDownloadURL = CreateCSVDownloadURL(
      custInfo.customerID,
      "blends",
      queryParams
    );
    console.log("Downloading " + csvDownloadURL);
    OpenURL(csvDownloadURL);
  };

  return (
    <>
      <Button
        onClick={() => {
          setShowDialog(true);
        }}
        label="Export"
      >
        <IconDownload />
      </Button>
      <Dialog fullWidth open={showDialog}>
        <DialogTitle>CSV Export</DialogTitle>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            variant="outlined"
            onClick={() => {
              setShowDialog(false);
            }}
          >
            <IconCancel />
          </Button>
          <Button label="Download" variant="contained" onClick={handleDownload}>
            <IconDownload />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const BlendListActions = () => {
  return (
    <TopToolbar>
      <FilterButton />
      <CreateButton />
      <BlendExportButton />
    </TopToolbar>
  );
};

export const BlendList = () => (
  <List
    perPage={25}
    pagination={<Pagination rowsPerPageOptions={[]} />}
    actions={<BlendListActions />}
    filters={blendFilters}
    filterDefaultValues={{ archived: false }}
    sort={{ field: "id", order: "ASC" }}
    storeKey={false}
  >
    <Datagrid bulkActionButtons={false}>
      <ReferenceField label="Blend" source="id" reference="blends" link="show">
        <TextField source="name" />
      </ReferenceField>
      <CustomDateField source="date" label="Creation Date" />
      <TextField source="year" label="Vintage" />
      <ReferenceField
        label="Varietal"
        source="varietalID"
        reference="varietals"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <WineColourField source="isRed" label="Color" />
      <DetailsButton />
      {hasScope(SCOPE.MANAGER) || hasScope(SCOPE.ADMIN) ? <EditButton /> : null}
    </Datagrid>
  </List>
);

const CreateEditForm = () => (
  <SimpleForm>
    <TextInput label="Blend" source="name" validate={required()} />
    <DateTimeInput
      label="Creation Date"
      source="date"
      defaultValue={new Date()}
    />
    <NumberInput label="Vintage" source="year" validate={validateYear} />
    <ReferenceInput
      source="varietalID"
      reference="varietals"
      defaultValue={0}
      sort={{ field: "name", order: "ASC" }}
    >
      <AutocompleteInput
        optionText="name"
        filterToQuery={(searchText) => ({ name: searchText })}
        label="Varietal"
      />
    </ReferenceInput>
    {hasScope(SCOPE.ADMIN) ? (
      <WineColorInput label="Color" source="isRed" />
    ) : null}
  </SimpleForm>
);

export const BlendEdit = () => (
  <Edit title={<BlendAppBarTitle />} redirect="list">
    <CreateEditForm />
  </Edit>
);

export const BlendCreate = () => (
  <Create redirect="list">
    <CreateEditForm />
  </Create>
);

export const BlendShow = () => (
  <Show title={<BlendAppBarTitle />}>
    <TabbedShowLayout>
      <Tab label="Overview">
        <TextField source="name" label="Blend Name" />
        <CustomDateField source="date" label="Creation Date" sortable={false} />
        <TextField source="year" label="Year" />
        <ReferenceField
          label="Varietal"
          source="varietalID"
          reference="varietals"
          sortable={false}
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <WineColourField source="isRed" label="Color" />
        <BooleanField source="archived" label="Archived" />
      </Tab>
      <Tab label="Lots">
        <ReferenceManyField
          label=""
          reference="lots"
          target="blendID"
          perPage={1000}
        >
          <Datagrid bulkActionButtons={false}>
            <ReferenceField
              label="Lot"
              source="id"
              reference="lots"
              link="show"
              sortable={false}
            >
              <LotNameField />
            </ReferenceField>
            <ReferenceField
              label="Blend"
              source="blendID"
              reference="blends"
              link={false}
              sortable={false}
            >
              <TextField source="name" />
            </ReferenceField>
            <TextField
              source="intendedUse"
              label="Intended Use"
              sortable={false}
            />
            <NumberField source="addTarget" label="Target" sortable={false} />
            <NumberField
              source="barrelAssociations.length"
              label="Barrels"
              sortable={false}
            />
            <VolumeField source="volume" label="Volume" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <CustomTab />
    </TabbedShowLayout>
  </Show>
);
