import { React, useState } from "react";
import {
  List,
  Datagrid,
  Pagination,
  ReferenceField,
  Show,
  TabbedShowLayout,
  Tab,
  NumberField,
  TextInput,
  useRecordContext,
  useListContext,
  Button,
  TopToolbar,
  ReferenceManyField,
} from "react-admin";

// Lib
import { custInfo } from "../lib/customer";
import { DetailsButton } from "../lib/detailsButton";
import { ChatBubbleTooltip } from "../lib/chatBubbleTooltip";
import { CustomDatetimeField } from "../lib/customDatetimeField";
import { AdditionRecommendationBox } from "../lib/additionRecommendationBox";
import { SectionHeader } from "../lib/sectionHeader";
import {
  AdditionConfirmationField,
  GenerateAddMethodText,
} from "../lib/additionConfirmationField";
import { AliasOrBarcodeReference } from "../lib/aliasWithBarcodeTooltip";
import {
  CreateCSVDownloadURL,
  CreateCSVDownloadQueryParams,
  OpenURL,
} from "../lib/csvDownload";
import { LotNameField } from "./lots";

// MUI
import IconDownload from "@mui/icons-material//CloudDownload";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconCancel from "@mui/icons-material//Cancel";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";

const LotAdditionAppBarTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span> {"Lot " + record.lotName + " Addition"}</span>;
};

const lotAdditionFilters = [
  <TextInput source="lotName" label="Lot" alwaysOn resettable />,
];

const LotAdditionExportButton = () => {
  const [showDialog, setShowDialog] = useState(false);
  const { sort, filterValues } = useListContext();

  const handleDownload = () => {
    setShowDialog(false);
    const queryParams = CreateCSVDownloadQueryParams(sort, filterValues);
    const csvDownloadURL = CreateCSVDownloadURL(
      custInfo.customerID,
      "events/lot_addition",
      queryParams
    );
    console.log("Downloading " + csvDownloadURL);
    OpenURL(csvDownloadURL);
  };

  return (
    <>
      <Button
        onClick={() => {
          setShowDialog(true);
        }}
        label="Export"
      >
        <IconDownload />
      </Button>
      <Dialog fullWidth open={showDialog}>
        <DialogTitle>CSV Export</DialogTitle>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            variant="outlined"
            onClick={() => {
              setShowDialog(false);
            }}
          >
            <IconCancel />
          </Button>
          <Button label="Download" variant="contained" onClick={handleDownload}>
            <IconDownload />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const LotAdditionListActions = () => {
  return (
    <TopToolbar>
      <LotAdditionExportButton />
    </TopToolbar>
  );
};

function confirmationSummaryText(simplified, summary) {
  const unit =
    summary.unit === "tablet" && summary.quantity > 1
      ? "tablets"
      : summary.unit;

  let text;
  if (simplified) {
    text = `${Math.round(summary.quantity)} ${unit}`;
  } else {
    const s = GenerateAddMethodText(summary);
    text = `${summary.quantity} ${unit} ${s}`;
  }

  return text;
}

const ConfirmationSummary = ({ simplified }) => {
  const record = useRecordContext();

  if (
    !record ||
    !record.confirmationSummary ||
    record.confirmationSummary.length === 0
  ) {
    return null;
  }

  return (
    <>
      {record.confirmationSummary.map((summary, index) => (
        <div>
          <Typography variant="body2" component="span" noWrap={true}>
            {confirmationSummaryText(simplified, summary)}
          </Typography>
        </div>
      ))}
    </>
  );
};

export const LotAdditionList = () => (
  <List
    perPage={25}
    pagination={<Pagination rowsPerPageOptions={[]} />}
    filters={lotAdditionFilters}
    actions={<LotAdditionListActions />}
    sort={{ field: "datetime", order: "DESC" }}
    storeKey={false}
  >
    <Datagrid bulkActionButtons={false}>
      <ReferenceField label="Lot" source="lotID" reference="lots" link="show">
        <LotNameField />
      </ReferenceField>
      <CustomDatetimeField source="timestamp" label="Start Time" />
      <CustomDatetimeField source="endTime" label="End Time" />
      <NumberField label="Barrels" source="barrelTreatedCount" />
      <NumberField
        source="avgAddPerBarrel"
        label={"Avg. Add per Barrel (ppm)"}
        options={{ minimumFractionDigits: 1, maximumFractionDigits: 1 }}
      />
      <ConfirmationSummary label="Addition Summary" simplified={true} />
      <DetailsButton />
    </Datagrid>
  </List>
);

const LotAdditionAddList = () => (
  <>
    <Datagrid bulkActionButtons={false}>
      <AliasOrBarcodeReference
        source="barrelID"
        reference="barrels"
        sortable={false}
      />
      <ReferenceField
        label="Lot"
        source="lotID"
        reference="lots"
        link="show"
        sortable={false}
      >
        <LotNameField />
      </ReferenceField>
      <NumberField source="addition" label="Addition (ppm)" sortable={false} />
      <CustomDatetimeField source="datetime" label="Time" sortable={false} />
      <ChatBubbleTooltip source="notes" sortable={false} />
      <DetailsButton />
    </Datagrid>
    <Pagination rowsPerPageOptions={[]} />
  </>
);

export const LotAdditionShow = () => (
  <Show title={<LotAdditionAppBarTitle />}>
    <TabbedShowLayout>
      <Tab label="Overview">
        <ReferenceField
          label="Lot"
          source="lotID"
          reference="lots"
          sortable={false}
          link="show"
        >
          <LotNameField />
        </ReferenceField>
        <CustomDatetimeField source="timestamp" label="Start Time" />
        <CustomDatetimeField source="endTime" label="End Time" />

        <SectionHeader title="Recommendation" />
        <AdditionRecommendationBox source="recommendation" />

        <SectionHeader title="Initial Lot Addition" />
        <NumberField
          source="confirmation.barrelCount"
          label="Number of Barrels"
        />
        <NumberField source="addition" label="Initial Addition (ppm)" />
        <AdditionConfirmationField source="confirmation" />

        <SectionHeader title="Lot Addition Summary" />
        <NumberField label="Barrels" source="barrelTreatedCount" />
        <NumberField
          source="avgAddPerBarrel"
          label={"Avg. Add per Barrel (ppm)"}
          options={{ minimumFractionDigits: 1, maximumFractionDigits: 1 }}
        />
        <ConfirmationSummary label="Lot Addition Sum" simplified={false} />
      </Tab>
      <Tab label="Additions">
        <ReferenceManyField
          reference="events/sulfate_addition"
          target="lotAdditionID"
          label=""
        >
          <LotAdditionAddList />
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
