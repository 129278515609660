import { stringify } from "query-string";

const OpenURL = (url) => {
  var a = document.createElement("a");
  a.href = url;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

const CreateCSVDownloadQueryParams = (sort, filterValues) => {
  const queryParams = stringify({
    sort: JSON.stringify([sort.field, sort.order]),
    filter: JSON.stringify(filterValues),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  return queryParams;
};

const CreateCSVDownloadURL = (custID, resource, queryParams) => {
  const csvDownloadURL =
    "/api/v2/c/" + custID + "/" + resource + "/csv?" + queryParams;

  return csvDownloadURL;
};

export { OpenURL, CreateCSVDownloadQueryParams, CreateCSVDownloadURL };
