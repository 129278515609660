import React from "react";

import { List, Datagrid, TextField } from "react-admin";

export const FirmwareList = (props) => (
  <List {...props} pagination={null} storeKey={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" label="Firmware Name" sortable={false} />
    </Datagrid>
  </List>
);
