import React from "react";

import {
  List,
  Datagrid,
  NumberField,
  TextField,
  BooleanField,
  TextInput,
  NumberInput,
  BooleanInput,
  Create,
  SimpleForm,
  Edit,
  email,
  useRecordContext,
  ChipField,
  ArrayField,
  SingleFieldList,
  Toolbar,
  SaveButton,
  DeleteButton,
  Button,
  useNotify,
} from "react-admin";

import MuiButton from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import RotateLeftIcon from "@mui/icons-material/RotateLeft";

import { httpClient } from "../lib/httpClient";

const validateEmail = email();

const ScopeBoolean = ({ source }) => {
  const record = useRecordContext();

  if (record.additionalScopes === undefined) {
    return null;
  }

  return (
    <>
      {record.additionalScopes[source] ? (
        <BooleanField source={`additionalScopes.${source}`} />
      ) : null}
    </>
  );
};

const CustomerChip = () => {
  const record = useRecordContext();
  const color = record.access === "manager" ? "primary" : "default";
  return <ChipField source="customerLongName" color={color} />;
};

const userFilters = [
  <TextInput source="email" label="Email" alwaysOn resettable />,
];

export const UserList = () => (
  <List
    pagination={null}
    sort={{ field: "email", order: "ASC" }}
    filters={userFilters}
    storeKey={false}
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <NumberField source="id" label="ID" />
      <TextField source="email" label="Email" />
      <ScopeBoolean source="admin" />
      <ScopeBoolean source="diagnostics" />
      <ArrayField source="customerAssociations" label="Customer">
        <SingleFieldList>
          <CustomerChip />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);

const InternalPrivilegeSwitches = () => {
  const record = useRecordContext();
  const emailAddress = record.email;
  const disabled = emailAddress.endsWith("@barrelwise.ca") ? false : true;
  return (
    <>
      <BooleanInput
        label="Admin"
        source="additionalScopes.admin"
        disabled={disabled}
      />
      <BooleanInput
        label="Diagnostics"
        source="additionalScopes.diagnostics"
        disabled={disabled}
      />
    </>
  );
};

const ReinviteButton = () => {
  const [open, setOpen] = React.useState(false);
  const [running, setRunning] = React.useState(false);

  const notify = useNotify();
  const record = useRecordContext();
  if (!record) return null;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handleReboot = () => {
    setRunning(true);
    httpClient("/api/v2/users/" + record.id + "/reinvite", {
      method: "POST",
    })
      .then(async (resp) => {
        const respJson = await resp.json();
        setOpen(false);
        setRunning(false);
        if (resp.ok) {
          notify(record.email + " reinvited", { type: "success" });
        } else {
          notify(respJson?.message || "unknown reinvite error", {
            type: "error",
          });
          console.log(respJson);
        }
      })
      .catch((error) => {
        setOpen(false);
        setRunning(false);
        notify(record.email + " reinvite error", { type: "error" });
        console.log(error);
      });
  };

  return (
    <>
      <Button label="Reinvite" size="medium" onClick={handleOpen}>
        <RotateLeftIcon />
      </Button>
      <Dialog open={open}>
        <DialogTitle>Confirm Reinvite</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to reinvite <b>{record.email}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiButton autoFocus onClick={handleCancel} disabled={running}>
            Cancel
          </MuiButton>
          <MuiButton onClick={handleReboot} disabled={running}>
            Reinvite
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const UserEditToolbar = () => (
  <Toolbar style={{ flex: 1, justifyContent: "space-between" }}>
    <SaveButton label="Save" />
    <ReinviteButton />
    <DeleteButton size="medium" />
  </Toolbar>
);

export const UserEdit = () => (
  <Edit redirect="list">
    <SimpleForm toolbar={<UserEditToolbar />}>
      <NumberInput label="ID" source="id" disabled />
      <TextInput label="Email" source="email" validate={validateEmail} />
      <InternalPrivilegeSwitches />
      <h2>Customer Access</h2>
      <ArrayField source="customerAssociations">
        <Datagrid bulkActionButtons={false} header={<></>}>
          <TextField source="customerLongName" label="Customer" />
          <TextField source="access" />
        </Datagrid>
      </ArrayField>
    </SimpleForm>
  </Edit>
);

export const UserCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <NumberInput label="ID" source="id" disabled />
      <TextInput label="Email" source="email" validate={validateEmail} />
      <p style={{ color: "red", fontWeight: "bold" }}>
        Warning: Creating this user will immediately send them an invite email.
      </p>
    </SimpleForm>
  </Create>
);
