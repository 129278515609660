import React from "react";

const SectionHeader = ({ title, ...rest }) => {
  return (
    <div {...rest}>
      <h2>{title}</h2>
    </div>
  );
};

export { SectionHeader };
