import React, { useCallback, useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useInput } from "react-admin";
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  format,
  parse,
} from "date-fns";
import { Button } from "react-admin";
import TextField from "@mui/material/TextField";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const styles = {
  buttonBox: {
    borderTop: "1px solid rgb(239, 242, 247)",
    paddingTop: 6,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  expanded: {
    position: "absolute",
    top: "30px",
    zIndex: "100",
    backgroundColor: "#ffffff",
    padding: "10px",
    borderRadius: "5px",
    boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.3)",
  },
};

// Converts from a local Date() object to a local date string
const toDate = (timestamp) => {
  return format(timestamp, "yyyy-MM-dd");
};

// Converts from a local date string to a local Date() object
const fromDate = (dateString) => {
  return parse(dateString, "yyyy-MM-dd", 0);
};

// Contains the user's local timezone
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const RangePickerInput = ({ label, source, resource }) => {
  // Field represents the state of the RA field
  const { field } = useInput({ source });

  // Ref state to hold a reference to the anchor element for the menu to hang off of
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  // two state objects to hold the state of the picker
  const [rangePickerFocusState, setRangePickerFocusState] = useState([-1, -1]);
  const [rangePickerState, setRangePickerState] = useState([
    {
      startDate: field.value.since ? fromDate(field.value.since) : new Date(),
      endDate: field.value.until ? fromDate(field.value.until) : new Date(),
      key: "selection",
    },
  ]);

  // We don't expect the field or element to change during the lifecycle of this
  // component. This allows us to use it in useEffect without also creating a
  // dependency field.value (which will change)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeCallback = useCallback(field.onChange, []);

  // The picker changes its range value when the user clicks the startDate but
  // before they've clicked the end date. By also watching the focusState, we
  // can wait until both startData and endDate are clicked before handling it.
  useEffect(() => {
    if (rangePickerFocusState[1] === 0) {
      onChangeCallback({
        timezone: timezone,
        since: toDate(rangePickerState[0].startDate),
        until: toDate(rangePickerState[0].endDate),
      });
      handleClose();
    }
  }, [onChangeCallback, rangePickerFocusState, rangePickerState]);

  const handleSetToday = () => {
    const today = new Date();
    field.onChange({
      timezone: timezone,
      since: toDate(today),
      until: toDate(today),
    });

    handleClose();
  };

  const handleSetThisWeek = () => {
    const today = new Date();
    const startOfWeekDate = startOfWeek(today);
    const endOfWeekDate = endOfWeek(today);
    field.onChange({
      timezone: timezone,
      since: toDate(startOfWeekDate),
      until: toDate(endOfWeekDate),
    });
    handleClose();
  };

  const handleSetThisMonth = () => {
    const today = new Date();
    const startOfMonthDate = startOfMonth(today);
    const endOfMonthDate = endOfMonth(today);
    field.onChange({
      timezone: timezone,
      since: toDate(startOfMonthDate),
      until: toDate(endOfMonthDate),
    });
  };

  const startFormattedDate = field.value.since;
  const endFormattedDate = field.value.until;
  const formattedValue =
    field.value.since === field.value.until
      ? startFormattedDate
      : `${startFormattedDate} ➞ ${endFormattedDate}`;

  // render the expanded version if expanded
  return (
    <>
      <TextField
        label={label}
        value={formattedValue}
        InputProps={{
          readOnly: true,
        }}
        variant="filled"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        style={{ width: "16em" }}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transitionDuration={0}
        disableRestoreFocus={true}
        variant="menu"
        sx={{
          ".MuiMenu-paper": {
            border: "1px solid rgba(0,0,0,0.3)",
            marginTop: "-1px",
            borderTopLeftRadius: 0,
          },
          "& .MuiMenu-list": { padding: 0 },
          "& .MuiMenuItem-root": { paddingTop: 0 },
        }}
      >
        <MenuItem
          disableGutters={true}
          dense={true}
          disableRipple={true}
          style={{ backgroundColor: "inherit" }}
        >
          <div>
            <DateRange
              editableDateInputs={true}
              onRangeFocusChange={setRangePickerFocusState}
              onChange={(s) => {
                setRangePickerState([s.selection]);
              }}
              ranges={rangePickerState}
            />
            <div style={styles.buttonBox}>
              <Button label="Today" onClick={handleSetToday} />
              <Button label="This Week" onClick={handleSetThisWeek} />
              <Button label="This Month" onClick={handleSetThisMonth} />
              <Button
                label="Cancel"
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
          </div>
        </MenuItem>
      </Menu>
    </>
  );
};

export default RangePickerInput;
