const styles = {
  timeDropdown: {
    padding: "5px",
    width: "125px",
  },
};

const timeOptions = ["Auto", "1 Day", "1 Week", "2 Weeks", "1 Month", "1 Year"];

const TimeDropdown = ({ value, onChange }) => {
  return (
    <select
      value={value}
      id="timeDropdown"
      onChange={onChange}
      style={styles.timeDropdown}
    >
      {timeOptions.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export { TimeDropdown };
