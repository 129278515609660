const AddUploadFeature = (dataProvider) => {
  return {
    ...dataProvider,
    create: async (resource, params) => {
      if (resource === "documents") {
        params.data.title = params.data.attachment.title;
        params.data.type = params.data.attachment.rawFile.type;
        params.data.size = params.data.attachment.rawFile.size;
        const b64file = await convertFileToBase64(params.data.attachment);
        params.data.attachment = b64file;
      }
      return await dataProvider.create(resource, params);
    },
  };
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });

export { AddUploadFeature };
