import { React, useState } from "react";

import {
  List,
  Datagrid,
  NumberField,
  TextField,
  TextInput,
  ReferenceInput,
  DateTimeInput,
  Edit,
  ReferenceField,
  SimpleForm,
  SelectInput,
  EditButton,
  NumberInput,
  Create,
  Pagination,
  Show,
  SimpleShowLayout,
  number,
  minValue,
  useRecordContext,
  useListContext,
  Button,
  TopToolbar,
  FilterButton,
  CreateButton,
} from "react-admin";

// Lib
import { custInfo } from "../lib/customer";
import { SectionHeader } from "../lib/sectionHeader";
import { ChatBubbleTooltip } from "../lib/chatBubbleTooltip";
import { DetailsButton } from "../lib/detailsButton";
import { AdditionConfirmationField } from "../lib/additionConfirmationField";
import { AdditionRecommendationBox } from "../lib/additionRecommendationBox";
import { CustomDatetimeField } from "../lib/customDatetimeField";
import { AliasOrBarcodeReference } from "../lib/aliasWithBarcodeTooltip";
import RangePickerInput from "../lib/dateRangePicker";
import { hasScope, SCOPE } from "../lib/auth";
import {
  CreateCSVDownloadURL,
  CreateCSVDownloadQueryParams,
  OpenURL,
} from "../lib/csvDownload";
import { LotNameField } from "./lots";

// MUI
import IconDownload from "@mui/icons-material//CloudDownload";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconCancel from "@mui/icons-material//Cancel";
import DialogActions from "@mui/material/DialogActions";

const AdditionAppBarTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span> {"Barrel " + record.barcode + " Addition"}</span>;
};

const additionFilters = [
  <TextInput source="barcodeOrAlias" label="Barrel" alwaysOn resettable />,
  <TextInput source="lotName" label="Lot" alwaysOn resettable />,
  <RangePickerInput source="dateRange" label="Date" />,
];

const AdditionExportButton = () => {
  const [showDialog, setShowDialog] = useState(false);
  const { sort, filterValues } = useListContext();

  const handleDownload = () => {
    setShowDialog(false);
    const queryParams = CreateCSVDownloadQueryParams(sort, filterValues);
    const csvDownloadURL = CreateCSVDownloadURL(
      custInfo.customerID,
      "events/sulfate_addition",
      queryParams
    );
    console.log("Downloading " + csvDownloadURL);
    OpenURL(csvDownloadURL);
  };

  return (
    <>
      <Button
        onClick={() => {
          setShowDialog(true);
        }}
        label="Export"
      >
        <IconDownload />
      </Button>
      <Dialog fullWidth open={showDialog}>
        <DialogTitle>CSV Export</DialogTitle>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            variant="outlined"
            onClick={() => {
              setShowDialog(false);
            }}
          >
            <IconCancel />
          </Button>
          <Button label="Download" variant="contained" onClick={handleDownload}>
            <IconDownload />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AdditionListActions = () => {
  return (
    <TopToolbar>
      <FilterButton />
      <CreateButton />
      <AdditionExportButton />
    </TopToolbar>
  );
};

export const AdditionList = () => (
  <List
    perPage={25}
    pagination={<Pagination rowsPerPageOptions={[]} />}
    filters={additionFilters}
    actions={<AdditionListActions />}
    sort={{ field: "datetime", order: "DESC" }}
    storeKey={false}
  >
    <Datagrid rowClick={null} bulkActionButtons={false}>
      <AliasOrBarcodeReference source="barrelID" reference="barrels" />
      <ReferenceField label="Lot" source="lotID" reference="lots" link="show">
        <LotNameField />
      </ReferenceField>
      <NumberField source="addition" label="Addition (ppm)" />
      <CustomDatetimeField source="datetime" label="Time" />
      <ChatBubbleTooltip source="notes" sortable={false} />
      <DetailsButton />
      {hasScope(SCOPE.MANAGER) || hasScope(SCOPE.ADMIN) ? <EditButton /> : null}
    </Datagrid>
  </List>
);

const validateAddition = [number(), minValue(0)];

const CreateEditForm = () => (
  <SimpleForm>
    <ReferenceInput source="barrelID" reference="barrels">
      <SelectInput optionText="barcode" label="Barcode" />
    </ReferenceInput>
    <DateTimeInput source="datetime" label="Time" />
    <NumberInput
      source="addition"
      label="Addition (ppm)"
      validate={validateAddition}
    />
    <ReferenceInput source="lotID" reference="lots">
      <SelectInput optionText="name" label="Lot" />
    </ReferenceInput>
    <TextInput label="Notes" source="notes" />
  </SimpleForm>
);

export const AdditionEdit = () => (
  <Edit redirect="list">
    <CreateEditForm />
  </Edit>
);

export const AdditionCreate = () => (
  <Create redirect="list">
    <CreateEditForm />
  </Create>
);

export const AdditionShow = () => (
  <Show title={<AdditionAppBarTitle />}>
    <SimpleShowLayout>
      <SectionHeader title="Summary" />
      <AliasOrBarcodeReference
        source="barrelID"
        reference="barrels"
        sortable={false}
      />
      <ReferenceField label="Lot" source="lotID" reference="lots" link="show">
        <LotNameField />
      </ReferenceField>
      <CustomDatetimeField source="datetime" label="Time" />
      <TextField label="Notes" source="notes" />
      <NumberField
        source="addition"
        label="Actual Addition (ppm)"
        sortable={false}
      />
      <AdditionConfirmationField source="confirmation" />
      <SectionHeader title="Recommendation" />
      <AdditionRecommendationBox />
    </SimpleShowLayout>
  </Show>
);
