import { Button, useRecordContext } from "react-admin";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import { Link } from "react-router-dom";

const linkBlue = "#005AA8";

const styles = {
  vcButton: {
    "&:enabled": {
      color: linkBlue,
    },
  },
};

const CreateVCButton = () => {
  const record = useRecordContext();
  return (
    <Button
      label="Create VC"
      component={Link}
      to={`./../virtual_composite/create?source={"lotID":${record.id}}`}
      sx={styles.vcButton}
    >
      <ControlPointDuplicateIcon />
    </Button>
  );
};

export { CreateVCButton };
