import { useRecordContext } from "react-admin";
import Tooltip from "@mui/material/Tooltip";
import ErrorOutlineIcon from "@mui/icons-material//ErrorOutline";
import { bwColours } from "./theme";

const styles = {
  icon: {
    color: bwColours.primary.red,
  },
  tooltip: {
    fontSize: "140%",
    padding: "1em",
    paddingTop: "0px",
    paddingBottom: "0px",
    lineHeight: "150%",
  },
};

const AddParameterIndicator = () => {
  const recs = useRecordContext();

  const addReady =
    recs.addMin && recs.addMax && recs.addMultiplier && recs.addTarget;

  const tooltipText = (
    <p style={styles.tooltip}>
      Additions recommendations will be unavailable because this lot is missing:
      <ul>
        {recs.addMin ? null : <li>Minimum Addition</li>}
        {recs.addMax ? null : <li>Maximum Addition</li>}
        {recs.addMultiplier ? null : <li>Multiplier</li>}
        {recs.addTarget ? null : <li>Target</li>}
      </ul>
    </p>
  );
  return addReady ? null : (
    <>
      <Tooltip title={tooltipText}>
        <ErrorOutlineIcon style={styles.icon} />
      </Tooltip>
    </>
  );
};

AddParameterIndicator.defaultProps = {
  label: "Addition",
  addLabel: true,
};

export { AddParameterIndicator };
