import { React, useState } from "react";
import {
  List,
  Datagrid,
  NumberField,
  TextField,
  TextInput,
  ReferenceInput,
  DateTimeInput,
  BooleanInput,
  Edit,
  ReferenceField,
  BooleanField,
  SimpleForm,
  SelectInput,
  EditButton,
  NumberInput,
  Create,
  Pagination,
  Show,
  FunctionField,
  useRecordContext,
  useListContext,
  Button,
  TopToolbar,
  FilterButton,
  TabbedShowLayout,
  Tab,
} from "react-admin";

// Lib
import { custInfo } from "../lib/customer";
import { hasScope, SCOPE } from "../lib/auth";
import { DetailsButton } from "../lib/detailsButton";
import { ChatBubbleTooltip } from "../lib/chatBubbleTooltip";
import { ContextTagField } from "../lib/contextTagField";
import { CustomDatetimeField } from "../lib/customDatetimeField";
import { WineColourField } from "../lib/wineColour";
import { OpenURL } from "../lib/csvDownload";
import { CONFIG } from "../lib/appConfig";
import { AliasOrBarcodeReference } from "../lib/aliasWithBarcodeTooltip";
import RangePickerInput from "../lib/dateRangePicker";
import { LotNameField } from "./lots";

// MUI
import IconDownload from "@mui/icons-material//CloudDownload";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconCancel from "@mui/icons-material//Cancel";
import DialogActions from "@mui/material/DialogActions";
import Link from "@mui/material/Link";

// Other
import { stringify } from "query-string";
import PropTypes from "prop-types";
import { CustomTab } from "../lib/customTab";

const fso2Filters = [
  <TextInput source="barcodeOrAlias" label="Barrel" alwaysOn resettable />,
  <TextInput source="lotName" label="Lot" alwaysOn resettable />,
  <NumberInput label="Less Than" source="fso2LTE" style={{ width: "120px" }} />,
  <NumberInput
    label="Greater Than"
    source="fso2GTE"
    style={{ width: "120px" }}
  />,
  <TextInput source="deviceSerial" label="Device" resettable />,
  <TextInput source="intendedUse" label="Intended Use" resettable />,
  <RangePickerInput source="dateRange" label="Date" />,
  <SelectInput
    source="type"
    choices={[
      { id: 1, name: "Barrel" },
      { id: 2, name: "Lot Composite" },
      { id: 3, name: "Ad Hoc" },
    ]}
  />,
];

const TicketField = ({ source }) => {
  const record = useRecordContext();
  if (!record) return null;

  return (
    <span>
      <Link
        href={
          CONFIG.GrafanaServer +
          "/d/diag/diagnostics?orgId=8&refresh=5s&var-ticket=" +
          record[source]
        }
        target="_new"
      >
        {record[source]}
      </Link>
    </span>
  );
};

TicketField.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string.isRequired,
};
TicketField.defaultProps = {
  label: "Ticket",
  addLabel: true,
};

const DeviceTitleField = () => (
  <FunctionField
    render={(record) =>
      record.deviceSerial === null ? "FS1" : `FS1 ${record.deviceSerial}`
    }
  />
);

DeviceTitleField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};
DeviceTitleField.defaultProps = {
  label: "Device",
  addLabel: true,
};

const FSO2ExportButton = () => {
  const [showDialog, setShowDialog] = useState(false);
  const { sort, filterValues } = useListContext();

  const showValidMeasurementsOnly = hasScope(SCOPE.MEMBER);

  const handleDownload = () => {
    setShowDialog(false);

    if (showValidMeasurementsOnly) {
      filterValues.valid = true;
    }

    const queryParams = stringify({
      sort: JSON.stringify([sort.field, sort.order]),
      filter: JSON.stringify(filterValues),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    const csvDownloadURL =
      "/api/v2/c/" +
      custInfo.customerID +
      "/events/fso2_reading/csv?" +
      queryParams;
    console.log("Downloading " + csvDownloadURL);
    OpenURL(csvDownloadURL);
  };

  return (
    <>
      <Button
        onClick={() => {
          setShowDialog(true);
        }}
        label="Export"
      >
        <IconDownload />
      </Button>
      <Dialog fullWidth open={showDialog}>
        <DialogTitle>CSV Export</DialogTitle>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            variant="outlined"
            onClick={() => {
              setShowDialog(false);
            }}
          >
            <IconCancel />
          </Button>
          <Button label="Download" variant="contained" onClick={handleDownload}>
            <IconDownload />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const FSO2ListActions = () => {
  return (
    <TopToolbar>
      <FilterButton />
      <FSO2ExportButton />
    </TopToolbar>
  );
};

export const FSO2List = () => (
  <List
    perPage={25}
    pagination={<Pagination rowsPerPageOptions={[]} />}
    filters={fso2Filters}
    actions={<FSO2ListActions />}
    sort={{ field: "datetime", order: "DESC" }}
    storeKey={false}
  >
    <Datagrid bulkActionButtons={false}>
      <AliasOrBarcodeReference source="barrelID" reference="barrels" />
      <ReferenceField label="Lot" source="lotID" reference="lots" link="show">
        <LotNameField />
      </ReferenceField>

      <ReferenceField
        source="blendID"
        reference="blends"
        label="Blend"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="intendedUse" label="Intended Use" />
      <NumberField
        source="fso2"
        label={"Free SO\u2082 (ppm)"}
        options={{ maximumFractionDigits: 0 }}
      />
      <CustomDatetimeField source="datetime" label="Measurement Time" />
      {hasScope(SCOPE.DIAGNOSTICS) ? (
        <TicketField source="ticket" label="Ticket" sortable={false} />
      ) : null}

      {hasScope(SCOPE.DIAGNOSTICS) ? (
        <BooleanField source="valid" label="Valid" />
      ) : null}
      <DeviceTitleField source="deviceSerial" />
      {hasScope(SCOPE.DIAGNOSTICS) ? (
        <ChatBubbleTooltip source="notes" sortable={false} />
      ) : null}
      <DetailsButton />
      {hasScope(SCOPE.DIAGNOSTICS) ? <EditButton /> : null}
    </Datagrid>
  </List>
);

// Available to admin only
export const FSO2Edit = () => (
  <Edit redirect="list">
    <SimpleForm>
      <TextInput source="ticket" disabled />
      <ReferenceInput source="barrelID" reference="barrels">
        <SelectInput optionText="barcode" label="Barrel" />
      </ReferenceInput>
      <DateTimeInput source="datetime" label="Measurement Time" />
      <ReferenceInput source="deviceID" reference="devices">
        <SelectInput optionText="name" label="Device" />
      </ReferenceInput>
      <NumberInput source="fso2" label={"Free SO\u2082 (ppm)"} />
      <BooleanInput source="valid" label="Valid" defaultValue={"true"} />
      <TextInput source="notes" label="Notes" multiline={true} />
    </SimpleForm>
  </Edit>
);

// Available to admin only
export const FSO2Create = () => (
  <Create redirect="list">
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="ticket" disabled />
      <ReferenceInput source="barrelID" reference="barrels">
        <SelectInput optionText="barcode" label="Barrel" />
      </ReferenceInput>
      <DateTimeInput source="datetime" label="Measurement Time" />
      <ReferenceInput source="deviceID" reference="devices">
        <SelectInput optionText="name" label="Device" />
      </ReferenceInput>
      <NumberInput source="fso2" label={"Free SO\u2082 (ppm)"} />
    </SimpleForm>
  </Create>
);

export const FSO2Show = () => (
  <Show>
    <TabbedShowLayout>
      <Tab label="Overview">
        <AliasOrBarcodeReference
          source="barrelID"
          reference="barrels"
          sortable={false}
        />
        <ReferenceField
          source="lotID"
          reference="lots"
          label="Lot"
          sortable={false}
          link="show"
        >
          <LotNameField />
        </ReferenceField>
        <ReferenceField
          source="blendID"
          reference="blends"
          label="Blend"
          sortable={false}
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="intendedUse" label="Intended Use" sortable={false} />
        <NumberField
          source="fso2"
          label={"Free SO\u2082 (ppm)"}
          sortable={false}
          options={{ maximumFractionDigits: 0 }}
        />
        <CustomDatetimeField source="datetime" label="Measurement Time" />
        {hasScope(SCOPE.DIAGNOSTICS) ? (
          <TicketField source="ticket" label="Ticket" sortable={false} />
        ) : (
          <TextField source="ticket" label="Ticket" sortable={false} />
        )}

        {hasScope(SCOPE.DIAGNOSTICS) ? (
          <BooleanField source="valid" label="Valid" sortable={false} />
        ) : null}
        <DeviceTitleField source="deviceSerial" />
        {hasScope(SCOPE.DIAGNOSTICS) ? (
          <WineColourField source="isRed" label="Color" />
        ) : null}
        {hasScope(SCOPE.DIAGNOSTICS) ? (
          <NumberField source="calibration" label="Calibration" />
        ) : null}
        <ContextTagField source="context" />
        <TextField source="notes" label="Notes" sortable={false} />
      </Tab>
      <CustomTab />
    </TabbedShowLayout>
  </Show>
);
