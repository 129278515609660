import React, { useState, useEffect } from "react";
import * as d3 from "d3";
import { useDataProvider } from "react-admin";
import moment from "moment";
import {
  ChangeTimeFormat,
  SetCanvas,
  SetScales,
  SetAxesToCanvas,
  GetYMax,
  SetPointsToCanvas,
  ExtractMinMaxDate,
} from "./helper";
import CircularProgress from "@mui/material/CircularProgress";

import { TimeDropdown } from "./timeDropdown";
import { FetchData } from "./dataFetcher";
import { LotDropdown } from "./lotDropdown";
import { SetBrush } from "./brush";
import { ResetButton } from "./resetButton";
import { SetTooltip } from "./tooltip";
import { bwColours } from "../../lib/theme";

const styles = {
  container: {
    width: "1080px",
    height: "700px",
  },
  filterBox: {
    position: "absolute",
    height: "200px",
    width: "130px",
    float: "right",
    top: "70px",
    right: "80px",
  },
  svgBox: {
    height: "400px",
    width: "900px",
  },
  titleBox: {
    width: "400px",
  },
  circularProgress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const ScatterChart = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState(null); // Store the fetched data
  const defaultSelectedTime = "1 Week";
  const defaultSelectedLot = "All";
  const today = moment().toDate();
  const [timeSelected, setTimeSelected] = useState(defaultSelectedTime);
  const [lotSelected, setLotSelected] = useState(defaultSelectedLot);
  const [windowMinDate, setWindowMinDate] = useState(today);
  const [windowMaxDate, setWindowMaxDate] = useState(
    ChangeTimeFormat(defaultSelectedTime)
  );
  const [resetClicked, setResetClicked] = useState(false);
  const [brushed, setBrushed] = useState(false);

  const handleTimeValueChange = (event) => {
    setTimeSelected(event.target.value);
  };
  const handleLotValueChange = (event) => {
    setLotSelected(event.target.value);
  };

  useEffect(() => {
    /*FIXME: when chart is brushed, we change timeSelected to custom, which is not part of the option in the dropdown.
    When reset is clicked, we set time selected back to its default, so this use effect will be triggered. 
    It does not seem to be an ideal way of doing this.
    */
    if (timeSelected === "custom") {
      return;
    }

    setWindowMinDate(ChangeTimeFormat(timeSelected));
    setWindowMaxDate(moment().toDate());
  }, [timeSelected]);

  useEffect(() => {
    if (!resetClicked) {
      return;
    }

    if (
      timeSelected === defaultSelectedTime &&
      lotSelected === defaultSelectedLot
    ) {
      return;
    }

    setWindowMinDate(moment().toDate());
    setWindowMaxDate(ChangeTimeFormat(defaultSelectedTime));
    setLotSelected(defaultSelectedLot);
    setTimeSelected(defaultSelectedTime);

    setResetClicked(false);
    setBrushed(false);
  }, [resetClicked, lotSelected, timeSelected]);

  useEffect(() => {
    const apiFilter = {
      valid: true,
      dateRange: {
        timezone: timezone,
        since: windowMinDate,
        until: windowMaxDate,
      },
    };

    if (lotSelected !== defaultSelectedLot) {
      apiFilter.lotName = lotSelected;
    }
    FetchData(dataProvider, setData, setLoading, setError, apiFilter);
  }, [
    windowMinDate,
    windowMaxDate,
    lotSelected,
    dataProvider,
    timezone,
    brushed,
  ]);

  useEffect(() => {
    if (!data) return;
    const yMax = GetYMax(data);

    let canvas = SetCanvas("#svgContainer", styles.svgBox);
    var tooltip = SetTooltip("#container");

    let scales;
    if (timeSelected === "Auto") {
      const [min, max] = ExtractMinMaxDate(data);
      scales = SetScales(min, max, yMax);
    } else {
      scales = SetScales(windowMinDate, windowMaxDate, yMax);
    }

    SetAxesToCanvas(canvas, scales);
    SetBrush(
      canvas,
      scales,
      setWindowMinDate,
      setWindowMaxDate,
      setTimeSelected,
      setBrushed
    );
    SetPointsToCanvas(canvas, data, scales, tooltip);

    return () => {
      d3.select("#canvas").remove();
      d3.select("#tooltip").remove();
    };
  }, [data, windowMinDate, windowMaxDate, timeSelected, loading]);

  useEffect(() => {
    // FIXME: Display error in devtools. Show the error in the UI in the future
    if (!error) return;
    console.log("error:", error);
  }, [error]);

  useEffect(() => {
    // FIXME: at this moment, there is no loading side-effect, but in the near future this is necessary (e.g. spinning wheel)
    // do nothing right now
    return;
  }, [loading]);

  return (
    <div id="container" style={styles.container}>
      <div style={styles.titleBox}>
        <h1 id="title">Free SO₂ (ppm)</h1>
      </div>

      {loading ? (
        <div style={styles.circularProgress}>
          <CircularProgress
            sx={{
              color: bwColours.primary.red,
            }}
          />
        </div>
      ) : (
        <div id="svgContainer"></div>
      )}
      <div style={styles.filterBox}>
        <h3>Filters</h3>
        <TimeDropdown value={timeSelected} onChange={handleTimeValueChange} />
        <LotDropdown
          value={lotSelected}
          data={data}
          onChange={handleLotValueChange}
        />
        <ResetButton setResetClicked={setResetClicked} />
      </div>
    </div>
  );
};

export default ScatterChart;
