import React, { useState, useEffect } from "react";
// FIXME: Switch this to react-hook-form
import { Field, Form } from "react-final-form";
import {
  Button,
  CardActions,
  CircularProgress,
  TextField,
} from "@mui/material";
import { Alert } from "@mui/material";
import AuthPage from "./authPage";
import { auth, authApi } from "../lib/auth";

const styles = {
  form: {
    padding: "16px 8px 0 8px",
    textAlign: "center",
  },
  alert: {
    margin: "0 8px",
  },
  input: {
    minHeight: "5.5em",
  },
  button: {
    width: "100%",
  },
  icon: {
    marginRight: "1em",
  },
};

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
    variant="standard"
  />
);

const LoginForm = (props) => {
  const { redirectTo } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const validate = (values) => {
    const errors = { email: undefined, password: undefined };

    if (!values.email) {
      errors.email = "Required";
    }
    if (!values.password) {
      errors.password = "Required";
    }
    return errors;
  };

  const submit = async ({ email, password }) => {
    ("submit");
    setLoading(true);
    setError("");

    let { error, user } = await auth.signIn({ email, password });
    if (user) {
      if (redirectTo) {
        window.location.replace(redirectTo);
      } else {
        window.location.replace("/");
      }
    } else {
      setLoading(false);
      if (error && error.message) setError(error.message);
      else {
        setError("Server error. Try again later.");
      }
    }
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div style={styles.form}>
            <div style={styles.input}>
              <Field
                autoFocus
                id="email"
                name="email"
                component={Input}
                label="Email"
                disabled={loading}
              />
            </div>
            <div style={styles.input}>
              <Field
                id="password"
                name="password"
                component={Input}
                label="Password"
                type="password"
                disabled={loading}
                autoComplete="current-password"
              />
            </div>
          </div>
          <Alert
            severity="error"
            style={{
              ...styles.alert,
              visibility: error ? "visible" : "hidden",
            }}
          >
            {error}
          </Alert>
          <CardActions>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              style={styles.button}
            >
              {loading && (
                <CircularProgress style={styles.icon} size={18} thickness={2} />
              )}
              Sign In
            </Button>
          </CardActions>
          <Button
            onClick={() => {
              props.passwordResetCallback(true);
            }}
            disabled={loading}
          >
            Forgot Password
          </Button>
        </form>
      )}
    />
  );
};

const PasswordResetForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState("");

  const validate = (values) => {
    const errors = { email: undefined };

    if (!values.email) {
      errors.email = "Required";
    }

    return errors;
  };

  const submit = async ({ email }) => {
    ("submit");
    setLoading(true);
    setError("");

    let resp = await authApi.resetPasswordForEmail(email);
    if (resp) {
      setLoading(false);
      setComplete(true);
    } else {
      setLoading(false);
      if (error && error.message) setError(error.message);
      else {
        setError("Server error. Try again later.");
      }
    }
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div style={styles.form}>
            <h3>Forgot Password</h3>
            <div style={styles.input}>
              <Field
                id="email"
                name="email"
                component={Input}
                label="Email"
                type="email"
                disabled={loading}
                autoComplete="new-password"
              />
            </div>
          </div>
          <Alert
            severity={complete ? "success" : "error"}
            style={{
              ...styles.alert,
              visibility: error || complete ? "visible" : "hidden",
            }}
          >
            {complete ? "Please check your email for instructions" : error}
          </Alert>
          <CardActions>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading || complete}
              style={styles.button}
            >
              {loading && (
                <CircularProgress style={styles.icon} size={18} thickness={2} />
              )}
              Continue
            </Button>
          </CardActions>
          <Button
            onClick={() => {
              props.passwordResetCallback(false);
            }}
            disabled={loading}
          >
            Back
          </Button>
        </form>
      )}
    />
  );
};

const LoginPage = (props) => {
  const [passwordResetMode, setPasswordResetMode] = useState(false);

  useEffect(() => {
    document.title = "BarrelWise - Login";
  }, []);

  return (
    <AuthPage>
      {passwordResetMode ? (
        <PasswordResetForm
          {...props}
          passwordResetCallback={setPasswordResetMode}
        />
      ) : (
        <LoginForm {...props} passwordResetCallback={setPasswordResetMode} />
      )}
    </AuthPage>
  );
};

export default LoginPage;
