/* 
Holds the current CustomerAssociation record for the logged-in session:
{
    "customerID": 101,
    "customerLongName": "Showcase Winery",
    "customerPreferences": {
        "temperatureUnit": ""
    }
}
*/
export let custInfo;

export const setCustInfo = (c) => {
  custInfo = c;
};
