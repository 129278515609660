import { React, useState } from "react";

import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  NumberField,
  TextInput,
  ReferenceInput,
  Edit,
  SimpleForm,
  NumberInput,
  BooleanInput,
  EditButton,
  Create,
  required,
  number,
  Pagination,
  minValue,
  Show,
  TabbedShowLayout,
  Tab,
  AutocompleteInput,
  useRecordContext,
  Button,
  TopToolbar,
  CreateButton,
  useListContext,
  ReferenceManyField,
  SelectInput,
  FilterButton,
  BooleanField,
} from "react-admin";

// Lib
import { custInfo } from "../lib/customer";
import { hasScope, SCOPE } from "../lib/auth";
import {
  VolumeField,
  ConvertGalToLitre,
  ConvertLitreToGal,
  _formatNum,
} from "../lib/volume";
import { DetailsButton } from "../lib/detailsButton";
import {
  MeasureOrAddField,
  MeasureOrAddDetailsButton,
} from "../lib/measureOrAddField";
import { CustomDatetimeField } from "../lib/customDatetimeField";
import { AliasOrBarcodeReference } from "../lib/aliasWithBarcodeTooltip";
import {
  CreateCSVDownloadURL,
  CreateCSVDownloadQueryParams,
  OpenURL,
} from "../lib/csvDownload";
import RangePickerInput from "../lib/dateRangePicker";

import { LotNameField } from "./lots";

// MUI
import IconDownload from "@mui/icons-material//CloudDownload";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconCancel from "@mui/icons-material//Cancel";
import DialogActions from "@mui/material/DialogActions";
import { CustomTab } from "../lib/customTab";

const archivedFilterChoices = [
  { id: false, name: "Hide archived" },
  { id: true, name: "Show only archived" },
];

const barrelFilters = [
  <TextInput label="Barrel" source="search" alwaysOn resettable />,
  <TextInput label="Lot" source="currentLotName" alwaysOn resettable />,
  <SelectInput
    source="archived"
    label="Archived"
    alwaysOn
    choices={archivedFilterChoices}
    emptyText="Show all"
  />,
  <SelectInput
    source="type"
    choices={[
      { id: "barrel", name: "Barrel" },
      { id: "keg", name: "Keg" },
      { id: "tank", name: "Tank" },
      { id: "tirage", name: "Tirage" },
      { id: "sutter", name: "Sutter" },
      { id: "bin", name: "Bin" },
    ]}
  />,
];

const BarrelAppBarTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span> {"Barrel " + record.barcode}</span>;
};

const BarrelExportButton = () => {
  const [showDialog, setShowDialog] = useState(false);
  const { sort, filterValues } = useListContext();

  const handleDownload = () => {
    setShowDialog(false);
    const queryParams = CreateCSVDownloadQueryParams(sort, filterValues);
    const csvDownloadURL = CreateCSVDownloadURL(
      custInfo.customerID,
      "barrels",
      queryParams
    );

    console.log("Downloading " + csvDownloadURL);
    OpenURL(csvDownloadURL);
  };

  return (
    <>
      <Button
        onClick={() => {
          setShowDialog(true);
        }}
        label="Export"
      >
        <IconDownload />
      </Button>
      <Dialog fullWidth open={showDialog}>
        <DialogTitle>CSV Export</DialogTitle>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            variant="outlined"
            onClick={() => {
              setShowDialog(false);
            }}
          >
            <IconCancel />
          </Button>
          <Button label="Download" variant="contained" onClick={handleDownload}>
            <IconDownload />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const BarrelsListActions = () => {
  return (
    <TopToolbar>
      <FilterButton />
      <CreateButton />
      <BarrelExportButton />
    </TopToolbar>
  );
};

export const BarrelList = () => (
  <List
    perPage={25}
    pagination={<Pagination rowsPerPageOptions={[]} />}
    filters={barrelFilters}
    filterDefaultValues={{ archived: false }}
    actions={<BarrelsListActions />}
    sort={{ field: "id", order: "ASC" }}
    storeKey={false}
  >
    <Datagrid bulkActionButtons={false}>
      <AliasOrBarcodeReference source="id" reference="barrels" />
      <ReferenceField
        label="Cooper"
        source="cooperID"
        reference="barrel_cooper"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="year" label="Year" />
      <VolumeField source="volume" />
      <CustomDatetimeField
        source="lastFSO2Read.datetime"
        label="Last Analyzed"
      />
      <NumberField
        source="lastFSO2Read.fso2"
        options={{ maximumFractionDigits: 0 }}
        label={"Free SO\u2082 (ppm)"}
      />
      <ReferenceField
        label="Lot"
        source="currentLot.id"
        reference="lots"
        link="show"
      >
        <LotNameField />
      </ReferenceField>
      <DetailsButton />
      {hasScope(SCOPE.MANAGER) || hasScope(SCOPE.ADMIN) ? <EditButton /> : null}
    </Datagrid>
  </List>
);

const validateYear = [number(), minValue(2000)];
const validatePositiveNumber = [number(), minValue(0)];

const BarrelCreateEditForm = () => {
  const volumeUnit = custInfo.customerPreferences.volumeUnit;
  const volumeLabel =
    volumeUnit && volumeUnit === "Gal" ? "Volume (Gal)" : "Volume (L)";

  const volumeFormat = (value) => {
    if (value && volumeUnit === "Gal") {
      return _formatNum(ConvertLitreToGal(value), 2);
    }
    return value;
  };

  return (
    <SimpleForm>
      <TextInput source="barcode" label="Barcode" validate={required()} />
      <TextInput source="alias" label="Barrel Alias" defaultValue="" />
      <NumberInput
        source="volume"
        label={volumeLabel}
        validate={validatePositiveNumber}
        format={volumeFormat}
      />
      <ReferenceInput
        source="cooperID"
        reference="barrel_cooper"
        sort={{ field: "name", order: "ASC" }}
      >
        <AutocompleteInput
          optionText="name"
          filterToQuery={(searchText) => ({ name: searchText })}
          label="Cooper"
        />
      </ReferenceInput>
      <ReferenceInput
        source="toastID"
        reference="barrel_toast"
        sort={{ field: "name", order: "ASC" }}
      >
        <AutocompleteInput
          optionText="name"
          filterToQuery={(searchText) => ({ name: searchText })}
          label="Toast"
        />
      </ReferenceInput>
      <ReferenceInput
        source="oakID"
        reference="barrel_oak"
        filterToQuery={(searchText) => ({ name: searchText })}
        sort={{ field: "name", order: "ASC" }}
      >
        <AutocompleteInput optionText="name" label="Oak" />
      </ReferenceInput>
      <ReferenceInput
        source="forestID"
        reference="barrel_forest"
        sort={{ field: "name", order: "ASC" }}
      >
        <AutocompleteInput
          optionText="name"
          filterToQuery={(searchText) => ({ name: searchText })}
          label="Forest"
        />
      </ReferenceInput>
      <TextInput source="label" label="Label" />
      <ReferenceInput
        source="typeID"
        reference="barrel_type"
        sort={{ field: "name", order: "ASC" }}
        defaultValue={0}
      >
        <AutocompleteInput
          optionText="name"
          filterToQuery={(searchText) => ({ name: searchText })}
          label="Type"
        />
      </ReferenceInput>
      <NumberInput source="year" label="Year" validate={validateYear} />
      <TextInput source="seasoning" label="Seasoning" />
      <NumberInput
        source="dryingTime"
        label="Drying Time"
        validate={validatePositiveNumber}
      />
      <BooleanInput source="toastedHead" label="Toasted Head" />
      <TextInput source="grain" label="Grain" />
    </SimpleForm>
  );
};

const handleBarrelSubmit = (data) => {
  const volumeUnit = custInfo.customerPreferences.volumeUnit;
  let modifiedValues;
  if (data.volume && volumeUnit === "Gal") {
    modifiedValues = {
      ...data,
      volume: ConvertGalToLitre(data.volume),
    };
  } else {
    modifiedValues = data;
  }

  return modifiedValues;
};
export const BarrelEdit = () => (
  <Edit
    title={<BarrelAppBarTitle />}
    redirect="list"
    transform={handleBarrelSubmit}
  >
    <BarrelCreateEditForm />
  </Edit>
);

export const BarrelCreate = () => (
  <Create redirect="list" transform={handleBarrelSubmit}>
    <BarrelCreateEditForm />
  </Create>
);

const BarrelEventListFilters = [
  <SelectInput
    label="Type"
    source="type"
    choices={[
      { id: "fso2", name: "Free SO₂ Measurement" },
      { id: "addition", name: "Addition" },
    ]}
    emptyText={"All"}
  />,
  <RangePickerInput source="dateRange" label="Date" />,
];

const BarrelEventList = () => {
  const record = useRecordContext();
  return (
    <List
      perPage={25}
      pagination={<Pagination rowsPerPageOptions={[]} />}
      filter={{ barrelID: record.id }}
      filters={BarrelEventListFilters}
      title={" "}
      storeKey={false}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="type" label="Event" sortable={false} />
        <MeasureOrAddField label="Free SO₂ (ppm)" sortable={false} />
        <CustomDatetimeField source="datetime" label="Time" sortable={false} />
        <ReferenceField
          label="Lot"
          source="lotID"
          reference="lots"
          link="show"
          sortable={false}
        >
          <LotNameField />
        </ReferenceField>
        <MeasureOrAddDetailsButton />
      </Datagrid>
    </List>
  );
};

const BarrelLotBarrelList = () => (
  <>
    <Datagrid bulkActionButtons={false}>
      <ReferenceField
        label="Lot"
        source="lotID"
        reference="lots"
        sortable={false}
        link="show"
      >
        <LotNameField />
      </ReferenceField>
      <CustomDatetimeField
        source="startTime"
        label="Date Added"
        sortable={false}
      />
      <CustomDatetimeField
        source="endTime"
        label="Date Removed"
        sortable={false}
      />
    </Datagrid>
    <Pagination rowsPerPageOptions={[]} />
  </>
);

export const BarrelShow = () => (
  <Show title={<BarrelAppBarTitle />}>
    <TabbedShowLayout>
      <Tab label="Overview">
        <TextField source="barcode" label="Barcode" link={false} />
        <TextField source="alias" label="Alias" link={false} />
        <VolumeField source="volume" />
        <TextField source="cooperName" />
        <TextField source="toastName" />
        <TextField source="oakName" />
        <TextField source="forestName" />
        <TextField source="label" />
        <TextField source="typeName" />
        <TextField source="year" label="Year" />
        <TextField source="seasoning" label="Seasoning" />
        <CustomDatetimeField
          source="lastFSO2Read.datetime"
          label="Last Analyzed"
        />
        <NumberField
          source="lastFSO2Read.fso2"
          options={{ maximumFractionDigits: 0 }}
          label={"Free SO\u2082 (ppm)"}
        />
        <ReferenceField
          label="Lot"
          source="currentLot.id"
          reference="lots"
          link="show"
        >
          <LotNameField />
        </ReferenceField>
        <BooleanField source="archived" label="Archived" />
      </Tab>
      <Tab label="Events">
        <ReferenceManyField reference="events" target="barrelID" label="">
          <BarrelEventList />
        </ReferenceManyField>
      </Tab>
      <Tab label="Lot History">
        <ReferenceManyField reference="lot_barrel" target="barrelID" label="">
          <BarrelLotBarrelList />
        </ReferenceManyField>
      </Tab>
      <CustomTab />
    </TabbedShowLayout>
  </Show>
);
