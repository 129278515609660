const styles = {
  lotDropdown: {
    padding: "5px",
    width: "125px",
  },
};

function GetLotList(array) {
  const lotList = [
    ...new Set(
      array.map((x) => {
        return x.lot;
      })
    ),
  ];
  lotList.sort();
  lotList.unshift("All");
  return lotList;
}

const LotDropdown = ({ value, data, onChange }) => {
  if (!data) return;

  const lotList = GetLotList(data);

  return (
    <select
      value={value}
      id="lotDropdown"
      onChange={onChange}
      style={styles.lotDropdown}
    >
      {lotList.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export { LotDropdown, GetLotList };
