import React, { useRef, useEffect } from "react";
import { Card } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { isProduction, getEnvironmentName } from "../lib/appConfig";
import { theme, bwColours } from "../lib/theme";

const styles = {
  background: {
    height: "100vh",
    width: "100vw",
    position: "fixed",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage:
      "radial-gradient(circle at 50% 14em, #393228 0%, #19110a 60%, #0a0a06 100%)",
    filter: "blur(0.3vmax)",
  },
  main: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    minHeight: 500,
    minWidth: 340,
    padding: "2em",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  logo: {
    width: 265,
    margin: "2em 2em 0 2em",
    display: "flex",
    justifyContent: "center",
  },
  environment: {
    color: bwColours.primary.beige,
  },
};

const backgroundImage = "/media/barrelbg.jpg";

const AuthPageInner = (props) => {
  const { children, ...rest } = props;
  const containerRef = useRef();

  let backgroundImageLoaded = false;
  const updateBackgroundImage = () => {
    if (!backgroundImageLoaded && containerRef.current) {
      containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
      backgroundImageLoaded = true;
    }
  };

  // Load background image asynchronously to speed up time to interactive
  const lazyLoadBackgroundImage = () => {
    if (backgroundImage) {
      const img = new Image();
      img.onload = updateBackgroundImage;
      img.src = backgroundImage;
    }
  };

  useEffect(() => {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage();
    }
  });

  return (
    <ThemeProvider theme={createTheme(theme)}>
      <div style={styles.background} ref={containerRef} />
      <div style={styles.main} {...rest}>
        <Card style={styles.card}>
          <img
            src="/media/logo-full-trans.png"
            alt="BarrelWise"
            style={styles.logo}
          />
          <h2 style={styles.environment}>
            {isProduction() || getEnvironmentName()}
          </h2>
          {children}
        </Card>
      </div>
    </ThemeProvider>
  );
};

export default AuthPageInner;
