import React from "react";
import { FunctionField, useRecordContext } from "react-admin";
import { custInfo } from "../lib/customer";

import PropTypes from "prop-types";

function _formatNum(value, dp) {
  return Number(value.toFixed(dp)).toLocaleString();
}

const litrePerUSG = 3.78541178;

function ConvertLitreToGal(value) {
  return value / litrePerUSG;
}
function ConvertGalToLitre(value) {
  return value * litrePerUSG;
}

const VolumeField = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  const value = record[source];
  if (!value) return null;

  const unit = custInfo.customerPreferences.volumeUnit;
  let renderText;

  if (unit && unit === "Gal") {
    renderText = _formatNum(ConvertLitreToGal(value), 0) + " Gal";
  } else {
    renderText = _formatNum(value, 0) + " L";
  }

  return <FunctionField render={() => `${renderText}`} />;
};
VolumeField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  detailed: PropTypes.bool.isRequired,
  textAlign: PropTypes.string,
};
VolumeField.defaultProps = {
  label: "Volume",
  addLabel: true,
  detailed: false,
  textAlign: "right",
};

export { VolumeField, ConvertGalToLitre, ConvertLitreToGal, _formatNum };
