import Button from "@mui/material/Button";

const ResetButton = ({ setResetClicked }) => {
  const handleClick = () => {
    setResetClicked(true);
  };
  return (
    <Button variant="outlined" size="small" onClick={handleClick}>
      Clear Filter
    </Button>
  );
};

export { ResetButton };
