import { useRecordContext, useDataProvider } from "react-admin";
import { FetchData } from "./dataFetcher";
import { useEffect, useState } from "react";
import * as d3 from "d3";
import { GetYMax } from "./helper";
import { RenderTooltipText } from "./tooltip";
import { bwColours } from "../../lib/theme";

const containerHeight = 400;
const containerWidth = 300;
const tooltipHeight = 100;
const margin = { top: 20, left: 50, bottom: 10, right: 100 };

const styles = {
  container: {
    width: `${containerWidth}px`,
    height: `${containerHeight}px`,
  },
  svg: {
    width: `${containerWidth}px`,
    height: `${containerHeight - 100}px`,
  },
  tooltip: {
    width: `${containerWidth}px`,
    height: `${tooltipHeight}px`,
  },
};

function CreateTranslationStr(x, y) {
  return `translate(${x},${y})`;
}

function setCanvas(ref, styles) {
  let svg = d3.select(ref);

  // Define clipping path
  svg
    .append("defs")
    .append("clipPath")
    .attr("id", "clip")
    .append("rect")
    .attr("x", margin.left)
    .attr("width", styles.svg.width)
    .attr("height", styles.svg.height);

  return svg;
}
function setTooltip(ref) {
  let tooltip = d3.select(ref).style("opacity", 1);
  return tooltip;
}
function setScales(categories, yMax, styles) {
  let xScale = d3
    .scaleBand()
    .domain(categories)
    .range([margin.left, containerWidth - margin.left])
    .padding(0.05);

  let yScale = d3
    .scaleLinear()
    .domain([0, yMax + 5])
    .range([
      containerHeight - tooltipHeight - margin.top,
      margin.bottom + margin.top,
    ]);

  return { xScale: xScale, yScale: yScale };
}
function setAxesToCanvas(canvas, scales) {
  let xAxis = d3.axisBottom().scale(scales.xScale);

  canvas
    .append("g")
    .attr("class", "x-axis")
    .attr(
      "transform",
      CreateTranslationStr(0, containerWidth - margin.top - margin.bottom)
    )
    .call(xAxis);

  // Add scale to Y axis
  let yAxis = d3.axisLeft().scale(scales.yScale);
  canvas
    .append("g")
    .attr("class", "y-axis")
    .attr("transform", CreateTranslationStr(margin.left, -margin.bottom))
    .call(yAxis);
}
function setPointsToCanvas(canvas, data, scales, tooltip) {
  var mouseover = function () {
    tooltip.style("opacity", 1);
  };
  var mousemove = function (d, i) {
    const text = RenderTooltipText(i);

    tooltip
      .html(text)
      .style("left", d3.pointer(this)[0] + 70 + "px")
      .style("top", d3.pointer(this)[1] + "px");
  };
  var mouseleave = function () {
    tooltip.style("opacity", 0);
  };
  const jitterScale = d3
    .scaleLinear()
    .domain([0, 1])
    .range([-scales.xScale.bandwidth() / 2, scales.xScale.bandwidth() / 2]);

  canvas
    .selectAll("circle")
    .data(data)
    .enter()
    .append("circle")
    .attr(
      "cx",
      (d) =>
        scales.xScale(d.lot) +
        scales.xScale.bandwidth() / 2 +
        jitterScale(Math.random())
    )
    .attr("cy", function (d) {
      return scales.yScale(parseFloat(d.fso2) + 1.2);
    })
    .attr("r", 5)
    .style("fill", bwColours.primary.red)
    .style("opacity", 0.4)
    .attr("clip-path", "url(#clip)")
    .on("mouseover", mouseover)
    .on("mousemove", mousemove)
    .on("mouseleave", mouseleave);
}

const VCScatterPlot = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [lot, setLot] = useState(null);

  useEffect(() => {
    if (!record) return;

    setLot(record.lotName);

    const apiFilter = {
      ids: record?.eventFSO2ReadIDs,
    };

    FetchData(dataProvider, setData, setLoading, setError, apiFilter);
  }, [record, dataProvider]);

  useEffect(() => {
    if (!data) return;

    let canvas = setCanvas("#svg", styles);
    var tooltip = setTooltip("#tooltip");
    const yMax = GetYMax(data);
    const scales = setScales([lot], yMax, styles);
    setAxesToCanvas(canvas, scales);
    setPointsToCanvas(canvas, data, scales, tooltip);
  }, [data, lot, loading]);

  useEffect(() => {
    if (!error) return;
    console.log("error:", error);
  }, [error]);

  return (
    <div id="container" style={styles.container}>
      <svg id="svg" style={styles.svg} />
      <div id="tooltip" class="tooltip" style={styles.tooltip} />
    </div>
  );
};

export default VCScatterPlot;
