import React from "react";
import { useRecordContext } from "react-admin";
import { DetailsButton } from "./detailsButton";

const MeasureOrAddField = () => {
  const record = useRecordContext();
  if (!record) return null;

  if (record.ppm === undefined) {
    return null;
  } else if (record.type === "fso2") {
    return (
      <span style={{ paddingLeft: "1.1em" }}>{record.ppm.toFixed(0)}</span>
    );
  } else if (
    record.type === "virtual composite" ||
    record.type === "lot composite"
  ) {
    return (
      <span style={{ paddingLeft: "1.1em" }}>{record.ppm.toFixed(1)}</span>
    );
  } else {
    return (
      <span style={{ textAlign: "right" }}>
        <span style={{ color: "#00A87B" }}>▲</span>
        {record.ppm.toFixed(1)}
      </span>
    );
  }
};

const MeasureOrAddDetailsButton = () => {
  const record = useRecordContext();
  if (!record) return null;

  if (record.type === "lot addition") {
    return (
      <DetailsButton
        resource="events/lot_addition"
        record={{ id: record.eventID }}
      />
    );
  } else if (record.type === "virtual composite") {
    return (
      <DetailsButton
        resource="virtual_composite"
        record={{ id: record.eventID }}
      />
    );
  } else if (record.type === "lot composite") {
    return (
      <DetailsButton
        resource="events/fso2_reading"
        record={{ id: record.eventID }}
      />
    );
  } else if (record.type === "addition") {
    return (
      <DetailsButton
        resource="events/sulfate_addition"
        record={{ id: record.eventID }}
      />
    );
  } else if (record.type === "fso2") {
    return (
      <DetailsButton
        resource="events/fso2_reading"
        record={{ id: record.eventID }}
      />
    );
  }
};

export { MeasureOrAddField, MeasureOrAddDetailsButton };
