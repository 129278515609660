import { NumberField, Labeled } from "react-admin";
import Card from "@mui/material/Card";
const AdditionRecommendationBox = () => {
  return (
    <Card variant="outlined" style={{ padding: 10 }}>
      <Labeled label="Free SO2 Before Addition (ppm)" style={{ padding: 10 }}>
        <NumberField source="recommendation.measurement" />
      </Labeled>
      <Labeled label="Target (ppm)" style={{ padding: 10 }}>
        <NumberField source="recommendation.target" />
      </Labeled>
      <Labeled label="Addition Multiplier" style={{ padding: 10 }}>
        <NumberField
          source="recommendation.multiplier"
          options={{ style: "percent" }}
        />
      </Labeled>
      <Labeled label="Maximum Add (ppm)" style={{ padding: 10 }}>
        <NumberField source="recommendation.max" />
      </Labeled>
      <Labeled label="Recommendation (ppm)" style={{ padding: 10 }}>
        <NumberField source="recommendation.result" />
      </Labeled>
    </Card>
  );
};

export { AdditionRecommendationBox };
