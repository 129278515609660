import React from "react";

import {
  List,
  Datagrid,
  NumberField,
  TextField,
  TextInput,
  Edit,
  SimpleForm,
  EditButton,
  Create,
  required,
  Pagination,
} from "react-admin";
import { WineColourField, WineColorInput } from "../lib/wineColour";

const varietalFilter = [
  <TextInput label="Name" source="name" alwaysOn resettable />,
];

export const VarietalList = () => (
  <List
    perPage={100}
    pagination={<Pagination rowsPerPageOptions={[]} />}
    filters={varietalFilter}
    sort={{ field: "name", order: "ASC" }}
    storeKey={false}
  >
    <Datagrid rowClick={null} bulkActionButtons={false}>
      <NumberField source="id" label="ID" />
      <TextField source="name" label="Name" />
      <WineColourField source="is_red" label="Color" />
      <EditButton />
    </Datagrid>
  </List>
);

export const VarietalEdit = () => (
  <Edit redirect="list">
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="name" label="Name" validate={required()} />
      <WineColorInput source="is_red" label="Color" />
    </SimpleForm>
  </Edit>
);

export const VarietalCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <TextInput label="ID" source="id" disabled />
      <TextInput source="name" label="Name" validate={required()} />
      <WineColorInput source="is_red" label="Color" />
    </SimpleForm>
  </Create>
);
