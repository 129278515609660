import { React } from "react";
import {
  Create,
  Datagrid,
  List,
  Pagination,
  SimpleForm,
  TextField,
  SelectInput,
  NumberField,
  SimpleShowLayout,
  Show,
  useRecordContext,
  ReferenceArrayField,
  ReferenceField,
} from "react-admin";

import Card from "@mui/material/Card";
import { FileStatusField } from "./documents";
import { CustomDatetimeField } from "../lib/customDatetimeField";
import { DetailsButton } from "../lib/detailsButton";
import { LotNameField } from "./lots";

export const TaskList = () => (
  <List
    perPage={25}
    pagination={<Pagination rowsPerPageOptions={[]} />}
    exporter={false}
    sort={{ field: "startTime", order: "DESC" }}
    storeKey={false}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" label="ID" sortable={false} />
      <CustomDatetimeField source="startTime" label="Time" sortable={false} />
      <TextField source="type" label="Type" sortable={false} />
      <FileStatusField source="status" sortable={false} />
      <NumberField source="runtime" label="Runtime" sortable={false} />
      <DetailsButton />
    </Datagrid>
  </List>
);

const taskOptions = [
  { id: "innovint_inbound", name: "InnoVint - Inbound" },
  /*
  Keep this here in case it will be used in the future.
  {
    id: "innovint_outbound_fso2",
    name: "InnoVint - Outbound Free SO2",
  },*/
  {
    id: "innovint_outbound_virtual_composite",
    name: "InnoVint - Outbound Virtual Composite",
  },
  { id: "vintrace_inbound", name: "Vintrace - Inbound" },
  /*{ id: "vintrace_outbound_fso2", name: "Vintrace - Outbound Free SO2" },*/
  {
    id: "vintrace_outbound_virtual_composite",
    name: "Vintrace - Outbound Virtual Composite",
  },
  { id: "apply_additions_param_rules", name: "Apply Additions Param Rules" },
];

export const TaskCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <SelectInput label="Type" source="type" choices={taskOptions} />
    </SimpleForm>
  </Create>
);

const taskOptionsAdmin = [
  {
    id: "barrelwise_blend_varietal_color_matching",
    name: "Blend Varietal Color Matching",
  },
  {
    id: "barrelwise_zero_volume_vessel",
    name: "Zero Volume Vessel",
  },
];

export const TaskCreateAdmin = () => (
  <Create redirect="list">
    <SimpleForm>
      <SelectInput label="Type" source="type" choices={taskOptionsAdmin} />
    </SimpleForm>
  </Create>
);

export const OutboundTaskStatsBox = () => {
  const record = useRecordContext();

  if (!record.custom) return null;

  return (
    <Card variant="outlined" style={{ padding: 10, marginBottom: 50 }}>
      <h4>Virtual Composites Synced</h4>
      {Array.isArray(record.custom.vcsSynced) ? (
        <ReferenceArrayField
          reference="virtual_composite"
          source="custom.vcsSynced"
        >
          <Datagrid bulkActionButtons={false}>
            <TextField source="id" label="ID" />
            <ReferenceField
              label="Lot"
              source="lotID"
              reference="lots"
              link="show"
            >
              <LotNameField />
            </ReferenceField>
            <CustomDatetimeField
              source="creationTime"
              label="Submission Time"
            />
            <DetailsButton />
          </Datagrid>
        </ReferenceArrayField>
      ) : (
        record.custom.vcsSynced
      )}

      <h4>Lot Composites Synced</h4>
      {Array.isArray(record.custom.lcsSynced) ? (
        <ReferenceArrayField
          reference="events/fso2_reading"
          source="custom.lcsSynced"
        >
          <Datagrid bulkActionButtons={false}>
            <TextField source="id" label="ID" />
            <ReferenceField
              label="Lot"
              source="lotID"
              reference="lots"
              link="show"
            >
              <LotNameField />
            </ReferenceField>
            <CustomDatetimeField source="datetime" label="Measurement Time" />
            <DetailsButton />
          </Datagrid>
        </ReferenceArrayField>
      ) : (
        record.custom.lcsSynced
      )}
    </Card>
  );
};

export const InboundTaskStatsBox = () => {
  const record = useRecordContext();

  return (
    <Card variant="outlined" style={{ padding: 10, marginBottom: 50 }}>
      <h4>Blends Created</h4>
      {Array.isArray(record.custom.blendsCreated) ? (
        <ReferenceArrayField reference="blends" source="custom.blendsCreated">
          <Datagrid bulkActionButtons={false}>
            <TextField source="id" label="ID" />
            <TextField source="name" />
            <DetailsButton />
          </Datagrid>
        </ReferenceArrayField>
      ) : (
        record.custom.blendsCreated
      )}

      <h4>Blends Updated</h4>
      {Array.isArray(record.custom.blendsUpdated) ? (
        <ReferenceArrayField reference="blends" source="custom.blendsUpdated">
          <Datagrid bulkActionButtons={false}>
            <TextField source="id" label="ID" />
            <TextField source="name" />
            <DetailsButton />
          </Datagrid>
        </ReferenceArrayField>
      ) : (
        record.custom.blendsUpdated
      )}

      <h4>Blends Archived</h4>
      {Array.isArray(record.custom.blendsArchived) ? (
        <ReferenceArrayField reference="blends" source="custom.blendsArchived">
          <Datagrid bulkActionButtons={false}>
            <TextField source="id" label="ID" />
            <TextField source="name" />
            <DetailsButton />
          </Datagrid>
        </ReferenceArrayField>
      ) : (
        record.custom.blendsArchived
      )}

      <h4>Lots Created</h4>
      {Array.isArray(record.custom.lotsCreated) ? (
        <ReferenceArrayField reference="lots" source="custom.lotsCreated">
          <Datagrid bulkActionButtons={false}>
            <TextField source="id" label="ID" />
            <TextField source="name" />
            <DetailsButton />
          </Datagrid>
        </ReferenceArrayField>
      ) : (
        record.custom.lotsCreated
      )}

      <h4>Lots Updated</h4>
      {Array.isArray(record.custom.lotsUpdated) ? (
        <ReferenceArrayField reference="lots" source="custom.lotsUpdated">
          <Datagrid bulkActionButtons={false}>
            <TextField source="id" label="ID" />
            <TextField source="name" />
            <DetailsButton />
          </Datagrid>
        </ReferenceArrayField>
      ) : (
        record.custom.lotsUpdated
      )}

      <h4>Lots Archived</h4>
      {Array.isArray(record.custom.lotsArchived) ? (
        <ReferenceArrayField reference="lots" source="custom.lotsArchived">
          <Datagrid bulkActionButtons={false}>
            <TextField source="id" label="ID" />
            <TextField source="name" />
            <DetailsButton />
          </Datagrid>
        </ReferenceArrayField>
      ) : (
        record.custom.lotsArchived
      )}

      <h4>Barrels Created</h4>
      {Array.isArray(record.custom.barrelsCreated) ? (
        <ReferenceArrayField reference="barrels" source="custom.barrelsCreated">
          <Datagrid bulkActionButtons={false}>
            <TextField source="id" label="ID" />
            <TextField source="barcode" />
            <TextField source="alias" />
            <DetailsButton />
          </Datagrid>
        </ReferenceArrayField>
      ) : (
        record.custom.barrelsCreated
      )}

      <h4>Barrels Updated</h4>
      {Array.isArray(record.custom.barrelsUpdated) ? (
        <ReferenceArrayField reference="barrels" source="custom.barrelsUpdated">
          <Datagrid bulkActionButtons={false}>
            <TextField source="id" label="ID" />
            <TextField source="barcode" />
            <TextField source="alias" />
            <DetailsButton />
          </Datagrid>
        </ReferenceArrayField>
      ) : (
        record.custom.barrelsUpdated
      )}

      <h4>Barrels Archived</h4>
      {Array.isArray(record.custom.barrelsArchived) ? (
        <ReferenceArrayField
          reference="barrels"
          source="custom.barrelsArchived"
        >
          <Datagrid bulkActionButtons={false}>
            <TextField source="id" label="ID" />
            <TextField source="barcode" />
            <TextField source="alias" />
            <DetailsButton />
          </Datagrid>
        </ReferenceArrayField>
      ) : (
        record.custom.barrelsArchived
      )}
    </Card>
  );
};

const StatsBox = () => {
  const record = useRecordContext();

  if (
    record.type === "apply_additions_param_rules" &&
    record.status === "success"
  ) {
    return <InboundTaskStatsBox />;
  }
  if (record.type.includes("inbound") && record.status === "success") {
    return <InboundTaskStatsBox />;
  }
  if (record.type.includes("outbound") && record.status === "success") {
    return <OutboundTaskStatsBox />;
  }
};

export const TaskShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <CustomDatetimeField source="startTime" />
        <TextField source="type" />
        <TextField source="status" />
        <TextField source="error" />
        <TextField source="runtime" />
        <StatsBox />
      </SimpleShowLayout>
    </Show>
  );
};
