import * as d3 from "d3";

const svgSize = {
  width: 940,
  height: 400,
};
const SetBrush = (
  canvas,
  scales,
  setWindowMinDate,
  setWindowMaxDate,
  setTimeSelected,
  setBrushed
) => {
  const { xScale } = scales;

  const brush = d3
    .brushX()
    .extent([
      [0, 0],
      [svgSize.width, svgSize.height],
    ])
    .on("end", brushEnd);

  const brushGroup = canvas.append("g").attr("class", "brush").call(brush);

  function brushEnd(event) {
    const selection = event.selection;
    if (selection === null) {
    } else {
      const mn = xScale.invert(selection[0]);
      const mx = xScale.invert(selection[1]);

      setWindowMinDate(mn);
      setWindowMaxDate(mx);
      setTimeSelected("custom");
      brushGroup.call(brush.move, null);
      setBrushed(true);
    }
  }
};

export { SetBrush };
