import React, { useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  Show,
  Pagination,
  ReferenceArrayField,
  ReferenceField,
  TextInput,
  Create,
  SimpleForm,
  DateTimeInput,
  SelectInput,
  ReferenceInput,
  CheckboxGroupInput,
  ReferenceArrayInput,
  TopToolbar,
  FilterButton,
  Button,
  useRecordContext,
  useListContext,
  TabbedShowLayout,
  Tab,
  Labeled,
} from "react-admin";

// Lib
import { custInfo } from "../lib/customer";
import { DetailsButton } from "../lib/detailsButton";
import { ChatBubbleTooltip } from "../lib/chatBubbleTooltip";
import { ContextTagField } from "../lib/contextTagField";
import { CustomDatetimeField } from "../lib/customDatetimeField";
import { AliasOrBarcodeReference } from "../lib/aliasWithBarcodeTooltip";
import RangePickerInput from "../lib/dateRangePicker";
import { OpenURL } from "../lib/csvDownload";
import { LotNameField } from "./lots";
import VCScatterPlot from "./charts/virtual_composite";

// MUI
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconDownload from "@mui/icons-material//CloudDownload";
import IconCancel from "@mui/icons-material//Cancel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

//Other
import moment from "moment";
import { stringify } from "query-string";
import { CustomTab } from "../lib/customTab";

const VirtualCompositeAppBarTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span> {"Lot " + record.lotName + " Virtual Composite"}</span>;
};

const VirtualCompositeExportButton = () => {
  const [showDialog, setShowDialog] = useState(false);
  const { sort, filterValues } = useListContext();

  // Form options
  const [includeIndividualMeasurements, setIncludeIndividualMeasurements] =
    useState(false);

  const handleDownload = () => {
    setShowDialog(false);

    const queryParams = stringify({
      sort: JSON.stringify([sort.field, sort.order]),
      filter: JSON.stringify(filterValues),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      includeMeasurements: includeIndividualMeasurements,
    });
    const csvDownloadURL =
      "/api/v2/c/" +
      custInfo.customerID +
      "/virtual_composite/csv?" +
      queryParams;
    console.log("Downloading " + csvDownloadURL);
    OpenURL(csvDownloadURL);
  };

  return (
    <>
      <Button
        onClick={() => {
          setShowDialog(true);
        }}
        label="Export"
      >
        <IconDownload />
      </Button>
      <Dialog fullWidth open={showDialog}>
        <DialogTitle>CSV Export</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={includeIndividualMeasurements}
                onChange={(ev) => {
                  setIncludeIndividualMeasurements(ev.target.checked);
                }}
                name="includeIndividualMeasurements"
                color="primary"
              />
            }
            label="Include individual measurements"
          />
        </DialogContent>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            variant="outlined"
            onClick={() => {
              setShowDialog(false);
            }}
          >
            <IconCancel />
          </Button>
          <Button label="Download" variant="contained" onClick={handleDownload}>
            <IconDownload />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const virtualCompositesFilters = [
  <TextInput label="Lot" source="lotName" alwaysOn resettable />,
  <TextInput source="intendedUse" label="Intended Use" resettable />,
  <RangePickerInput source="dateRange" label="Date" />,
];

const VirtualCompositeListActions = () => {
  return (
    <TopToolbar>
      <FilterButton />
      <VirtualCompositeExportButton />
    </TopToolbar>
  );
};

export const VirtualCompositeList = () => (
  <List
    perPage={25}
    pagination={<Pagination rowsPerPageOptions={[]} />}
    filters={virtualCompositesFilters}
    actions={<VirtualCompositeListActions />}
    sort={{ field: "creationTime", order: "DESC" }}
    storeKey={false}
  >
    <Datagrid bulkActionButtons={false}>
      <ReferenceField label="Lot" source="lotID" reference="lots" link="show">
        <LotNameField />
      </ReferenceField>
      <ReferenceField
        label="Blend"
        source="blendID"
        reference="blends"
        link="show"
      >
        <LotNameField />
      </ReferenceField>
      <TextField label="Intended Use" source="intendedUse" link={false} />
      <ContextTagField source="context" />
      <NumberField
        source="fso2Avg"
        label={"Avg. Free SO\u2082 (ppm)"}
        options={{ minimumFractionDigits: 1, maximumFractionDigits: 1 }}
      />
      <NumberField source="barrelCount" label="Barrels" />
      <CustomDatetimeField source="creationTime" label="Submission Time" />
      <ChatBubbleTooltip source="message" label="Notes" sortable={false} />
      <DetailsButton />
    </Datagrid>
  </List>
);

const VirtualCompositeOverviewTab = () => {
  return (
    <>
      <Stack spacing={1}>
        <Labeled>
          <ReferenceField
            label="Lot"
            source="lotID"
            reference="lots"
            sortable={false}
            link="show"
          >
            <LotNameField />
          </ReferenceField>
        </Labeled>
        <Labeled>
          <ReferenceField
            label="Blend"
            source="blendID"
            reference="blends"
            sortable={false}
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
        </Labeled>
        <Labeled>
          <ReferenceField
            label="Intended Use"
            source="lotID"
            reference="lots"
            sortable={false}
            link={false}
          >
            <TextField source="intendedUse" />
          </ReferenceField>
        </Labeled>
        <Labeled>
          <ContextTagField source="context" />
        </Labeled>
        <Labeled>
          <NumberField
            source="fso2Avg"
            label={"Avg. Free SO\u2082 (ppm)"}
            sortable={false}
            options={{ minimumFractionDigits: 1, maximumFractionDigits: 1 }}
          />
        </Labeled>
        <Labeled>
          <NumberField source="barrelCount" label="Barrels" sortable={false} />
        </Labeled>
        <Labeled>
          <CustomDatetimeField source="creationTime" label="Submission Time" />
        </Labeled>
        <Labeled>
          <TextField
            source="notes"
            label="Notes"
            sortable={false}
            link={false}
          />
        </Labeled>
      </Stack>
    </>
  );
};

export const VirtualCompositeShow = () => (
  <Show title={<VirtualCompositeAppBarTitle />}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <Grid container spacing={6}>
          <Grid item xs={3}>
            <VirtualCompositeOverviewTab />
          </Grid>
          <Grid item xs={4}>
            <VCScatterPlot />
          </Grid>
        </Grid>
      </Tab>
      <Tab label="Measurement">
        <ReferenceArrayField
          label={"Free SO\u2082 (ppm)"}
          reference="events/fso2_reading"
          source="eventFSO2ReadIDs"
        >
          <Datagrid bulkActionButtons={false}>
            <AliasOrBarcodeReference
              source="barrelID"
              reference="barrels"
              sortable={false}
            />
            <CustomDatetimeField source="datetime" label="Measurement Time" />
            <NumberField
              source="fso2"
              label={"Free SO\u2082 (ppm)"}
              sortable={false}
              options={{ maximumFractionDigits: 0 }}
            />
            <DetailsButton />
          </Datagrid>
        </ReferenceArrayField>
      </Tab>
      <CustomTab />
    </TabbedShowLayout>
  </Show>
);

const UnassociatedFSO2List = () => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <ReferenceArrayInput
      source="eventFSO2ReadIDs"
      reference="events/fso2_reading"
      filter={{ lotID: record.lotID, valid: true, inVC: false }}
      label=""
      perPage={1000}
    >
      <CheckboxGroupInput
        translateChoice={false}
        optionText={(record) =>
          `${record.barcode} - ${record.fso2} ppm - ${moment(
            new Date(record.datetime)
          ).format("LLLL")}`
        }
        row={false}
      />
    </ReferenceArrayInput>
  );
};

export const VirtualCompositeCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <DateTimeInput
        label="Creation Time"
        source="creationTime"
        defaultValue={new Date()}
        disabled
      />
      <ReferenceInput source="lotID" reference="lots">
        <SelectInput optionText="name" disabled label="Lot" />
      </ReferenceInput>
      <SelectInput
        source="context"
        choices={[
          { id: "before addition", name: "Before Addition" },
          { id: "spotcheck after add", name: "Spotcheck After Add" },
        ]}
      />
      <TextInput source="message" label="Notes" multiline={true} />
      <UnassociatedFSO2List />
    </SimpleForm>
  </Create>
);
