import React, { Component } from "react";
import { Loading } from "react-admin";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import {
  validateLogin,
  hasScope,
  SCOPE,
  getCustomerAssociations,
  auth,
  isValidated,
  logout,
} from "./lib/auth";
import { load } from "./lib/appConfig";

import { AdminApp } from "./app-admin.js";
import { CustomerApp } from "./app-customer.js";

import LoginPage from "./auth/login";
import ChangePasswordPage from "./auth/changepass";
import CustomerSelectionPage from "./auth/custselect";

// HoC to intercept unauthenticated requests and send them to the login page
const withAuth = (Element) => {
  return (props) => {
    if (auth.session() && isValidated()) {
      return <Element {...props} />;
    } else {
      console.log("No session - redirect to login");
      logout();
      return <Navigate to="/login" {...props} />;
    }
  };
};

const WrappedCustomerApp = withAuth(CustomerApp);

const WrappedDefaultApp = withAuth((props) => {
  if (hasScope(SCOPE.ADMIN)) {
    return <AdminApp {...props} />;
  } else {
    const custs = getCustomerAssociations();
    if (custs && custs.length === 1) {
      return <Navigate to={"/c/" + custs[0].customerID + "/"} />;
    } else {
      return <CustomerSelectionPage custs={custs} />;
    }
  }
});

// This App wrapper loads the app config and then
// manages routing between our two React-Admin apps
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    // Load the app config
    await load();

    if (auth.session()) {
      // Load userdata
      await validateLogin();
    }

    if (this._isMounted) {
      this.setState({
        loaded: true,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Loading
          loadingPrimary="Loading"
          loadingSecondary="Please wait a moment"
        />
      );
    }

    return (
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route path="/changepass" element={<ChangePasswordPage />} />
          <Route
            path="/invite"
            element={<ChangePasswordPage fromFlow="invite" />}
          />
          <Route
            path="/recovery"
            element={<ChangePasswordPage fromFlow="recovery" />}
          />
          <Route path="/c/:custId/*" element={<WrappedCustomerApp />} />
          <Route path="/*" element={<WrappedDefaultApp />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
