import { useRecordContext } from "react-admin";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

export function GenerateAddMethodText(addConfirmation) {
  let methodString = "";
  switch (addConfirmation.method) {
    case "KMS powder":
      methodString = `${addConfirmation.method}`;
      break;
    case "SMS powder":
      methodString = `${addConfirmation.method}`;
      break;
    case "SO₂ solution":
      methodString = `${addConfirmation.concentration * 100}% ${
        addConfirmation.method
      }`;
      break;
    case "SO₂ tablet":
      methodString = `${addConfirmation.concentration}g/tbl ${addConfirmation.method}`;
      break;
    case "PPM":
      const roundFactor = addConfirmation.roundFactor;
      if (roundFactor) {
        methodString = `SO₂ (intervals of ${addConfirmation.roundFactor})`;
      } else {
        methodString = "SO₂";
      }
      break;
    // no default
  }
  return methodString;
}

const AdditionConfirmationField = () => {
  const record = useRecordContext();
  if (!record) return null;

  const recs = record.confirmation;
  if (recs === undefined || Object.keys(recs).length === 0) {
    return "";
  }
  const method = GenerateAddMethodText(recs);

  return (
    <Typography variant="body2" component="span" noWrap={true}>
      {recs.quantity} {recs.unit} of {method}
    </Typography>
  );
};

AdditionConfirmationField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
};
AdditionConfirmationField.defaultProps = {
  label: "Confirmation",
  addLabel: true,
};

export { AdditionConfirmationField };
