import { DateField } from "react-admin";

export const CustomDatetimeField = (props) => (
  <DateField
    showTime={true}
    options={{
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    }}
    {...props}
  />
);

export const CustomDateField = (props) => (
  <DateField
    options={{
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    }}
    {...props}
  />
);
