import React, { useState, useEffect } from "react";
import { Admin, Resource, Loading } from "react-admin";
import { useParams } from "react-router-dom";

import simpleRestProvider from "ra-data-simple-rest";

import InvertColorsIcon from "@mui/icons-material//InvertColors";
import TimelineIcon from "@mui/icons-material//Timeline";
import BackupIcon from "@mui/icons-material//Backup";
import PolylineIcon from "@mui/icons-material/Polyline";
import Smartphone from "@mui/icons-material/Smartphone";
import {
  BarrelIcon,
  BatchIcon,
  VirtualCompositeIcon,
  SulfateAdditionIcon,
  LotAdditionIcon,
} from "./lib/icons";

import { fetchJson } from "./lib/httpClient";
import { authProvider, hasScope, SCOPE, setScopesCustomerID } from "./lib/auth";
import { theme } from "./lib/theme";
import AppLayout from "./lib/appLayout";

import {
  BarrelList,
  BarrelEdit,
  BarrelCreate,
  BarrelShow,
} from "./resources/barrels";
import { FSO2List, FSO2Create, FSO2Edit, FSO2Show } from "./resources/fso2";
import { LotList, LotCreate, LotShow, LotEdit } from "./resources/lots";
import {
  BlendList,
  BlendEdit,
  BlendCreate,
  BlendShow,
} from "./resources/blends";
import {
  VirtualCompositeList,
  VirtualCompositeShow,
  VirtualCompositeCreate,
} from "./resources/virtual_composites";
import {
  AdditionList,
  AdditionEdit,
  AdditionCreate,
  AdditionShow,
} from "./resources/sulfate_add";
import { LotAdditionList, LotAdditionShow } from "./resources/lot_add";
import Dashboard from "./resources/dashboard";
import {
  DocumentList,
  DocumentCreate,
  DocumentShow,
} from "./resources/documents";
import { AppInstanceList } from "./resources/app_instance";
import { TaskList, TaskCreate, TaskShow } from "./resources/tasks";

import { AddUploadFeature } from "./lib/documentUploadHandler";
import { setCustInfo } from "./lib/customer";

export const CustomerApp = (props) => {
  const { custId } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log(`Fetching customer id=${custId}`);
    fetchJson("/api/v2/c/" + custId).then(async (resp) => {
      // Set some global state for this customer
      setCustInfo(resp.json);
      setScopesCustomerID(custId);
      document.title = "BarrelWise - " + resp.json.customerLongName;

      setLoading(false);
    });
  }, [custId]);

  if (loading) {
    return (
      <Loading
        loadingPrimary="Loading"
        loadingSecondary="Please wait a moment"
      />
    );
  }

  const dataProvider = AddUploadFeature(
    simpleRestProvider("/api/v2/c/" + custId, fetchJson)
  );

  return (
    <Admin
      dataProvider={dataProvider}
      basename={"/c/" + custId}
      authProvider={authProvider}
      layout={AppLayout}
      theme={theme}
      loginPage={() => null}
      dashboard={Dashboard}
    >
      <Resource
        name="barrels"
        options={{ label: "Barrels" }}
        list={BarrelList}
        create={
          hasScope(SCOPE.MANAGER) || hasScope(SCOPE.ADMIN)
            ? BarrelCreate
            : undefined
        }
        edit={
          hasScope(SCOPE.MANAGER) || hasScope(SCOPE.ADMIN)
            ? BarrelEdit
            : undefined
        }
        show={BarrelShow}
        icon={BarrelIcon}
      />
      <Resource
        name="lots"
        options={{ label: "Lots" }}
        list={LotList}
        create={
          hasScope(SCOPE.MANAGER) || hasScope(SCOPE.ADMIN)
            ? LotCreate
            : undefined
        }
        show={LotShow}
        icon={BatchIcon}
        edit={
          hasScope(SCOPE.ADMIN) || hasScope(SCOPE.MANAGER) ? LotEdit : undefined
        }
      />

      <Resource
        name="blends"
        options={{ label: "Blends" }}
        list={BlendList}
        create={
          hasScope(SCOPE.MANAGER) || hasScope(SCOPE.ADMIN)
            ? BlendCreate
            : undefined
        }
        edit={
          hasScope(SCOPE.MANAGER) || hasScope(SCOPE.ADMIN)
            ? BlendEdit
            : undefined
        }
        show={BlendShow}
        icon={InvertColorsIcon}
      />
      <Resource
        name="events/fso2_reading"
        options={{ label: "Free SO\u2082" }}
        list={FSO2List}
        show={FSO2Show}
        create={hasScope(SCOPE.ADMIN) ? FSO2Create : undefined}
        edit={hasScope(SCOPE.ADMIN) ? FSO2Edit : undefined}
        icon={TimelineIcon}
      />
      <Resource
        name="virtual_composite"
        options={{ label: "Virtual Composites" }}
        list={VirtualCompositeList}
        show={VirtualCompositeShow}
        icon={VirtualCompositeIcon}
        create={hasScope(SCOPE.ADMIN) ? VirtualCompositeCreate : undefined}
      />

      <Resource
        name="events/sulfate_addition"
        options={{ label: "Additions" }}
        list={AdditionList}
        create={hasScope(SCOPE.ADMIN) ? AdditionCreate : undefined}
        edit={hasScope(SCOPE.ADMIN) ? AdditionEdit : undefined}
        show={AdditionShow}
        icon={SulfateAdditionIcon}
      />
      <Resource
        name="events/lot_addition"
        options={{ label: "Lot Additions" }}
        list={LotAdditionList}
        show={LotAdditionShow}
        icon={LotAdditionIcon}
      />

      <Resource
        name="documents"
        options={{ label: "Documents" }}
        list={hasScope(SCOPE.ADMIN) ? DocumentList : undefined}
        create={hasScope(SCOPE.ADMIN) ? DocumentCreate : undefined}
        show={hasScope(SCOPE.ADMIN) ? DocumentShow : undefined}
        icon={BackupIcon}
      />
      <Resource
        name="tasks"
        options={{ label: "Tasks" }}
        list={hasScope(SCOPE.ADMIN) ? TaskList : undefined}
        create={hasScope(SCOPE.ADMIN) ? TaskCreate : undefined}
        show={hasScope(SCOPE.ADMIN) ? TaskShow : undefined}
        icon={PolylineIcon}
      />
      <Resource
        name="appinstance"
        options={{ label: "App Instances" }}
        list={hasScope(SCOPE.ADMIN) ? AppInstanceList : undefined}
        icon={Smartphone}
      />

      {/* Hidden resources that are used for reference fields */}
      <Resource name="barrel_cooper" />
      <Resource name="barrel_toast" />
      <Resource name="barrel_oak" />
      <Resource name="barrel_forest" />
      <Resource name="barrel_type" />
      <Resource name="lot_barrel" />
      <Resource name="devices" />
      <Resource name="varietals" />
      <Resource name="events" />
    </Admin>
  );
};
