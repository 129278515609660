import { Tab, useRecordContext } from "react-admin";
import { hasScope, SCOPE } from "../lib/auth";
import Box from "@mui/material/Box";

const CustomTab = (props) => {
  const record = useRecordContext();

  if (!hasScope(SCOPE.DIAGNOSTICS)) {
    return null;
  }

  return (
    <Tab label="Custom" {...props}>
      <Box component="div" sx={{ overflow: "auto", whiteSpace: "pre" }}>
        {JSON.stringify(record.custom, null, 2)}
      </Box>
    </Tab>
  );
};

export { CustomTab };
