import React from "react";
import Tooltip from "@mui/material/Tooltip";
import MessageIcon from "@mui/icons-material/Message";
import { useRecordContext } from "ra-core";

const ChatBubbleTooltip = () => {
  const record = useRecordContext();
  if (record === undefined) {
    return null;
  }

  return record.notes ? (
    <Tooltip
      title={
        <p
          style={{
            fontSize: "1.8em",
            lineHeight: 1,
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          {record.notes}
        </p>
      }
    >
      <MessageIcon />
    </Tooltip>
  ) : null;
};

export { ChatBubbleTooltip };
