/* This can be imported throughout the app */
export let CONFIG = {};

/* This is called once during application initialization. */
export async function load() {
  let response = await fetch("/api/v2/config");
  let json = await response.json();
  CONFIG = json;
}

export function isProduction() {
  if (CONFIG.EnvironmentName === "production") {
    return true;
  } else {
    return false;
  }
}

export function getEnvironmentName() {
  return CONFIG.EnvironmentName || "Development";
}
