import {
  List,
  Datagrid,
  TextField,
  Pagination,
  Button,
  useNotify,
  useRecordContext,
} from "react-admin";
import { CustomDatetimeField } from "../lib/customDatetimeField";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { DeleteButton } from "react-admin";
import { httpClient } from "../lib/httpClient";

const ChatNotificationButton = () => {
  const notify = useNotify();
  const record = useRecordContext();

  const handleSendChatNotification = async () => {
    if (!record) {
      console.log("No record set. Aborting notification request.");
      return;
    }

    try {
      const resp = await httpClient(
        `/api/v2/c/${record.customerID}/appinstance/${record.id}/notify`,
        {
          method: "POST",
        }
      );

      if (resp.ok) {
        notify(
          `Successfully sent chat notification to customer ${record.customerID}, device ${record.deviceName}`,
          { type: "success" }
        );
      } else {
        notify(
          `Failed to notify customer ${record.customerID}, device ${record.deviceName}`,
          { type: "error" }
        );
      }
    } catch (err) {
      notify(
        `Failed to notify customer ${record.customerID}, device ${record.deviceName}`,
        { type: "error" }
      );
    }
  };

  return (
    <Button
      size="medium"
      onClick={handleSendChatNotification}
      label="Send Chat Notification"
    >
      <ChatBubbleIcon />
    </Button>
  );
};

export const AppInstanceList = () => (
  <List
    perPage={25}
    pagination={<Pagination rowsPerPageOptions={[]} />}
    exporter={false}
    sort={{ field: "lastLogin", order: "DESC" }}
    storeKey={false}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField source="deviceName" label="Device Name" sortable={true} />
      <CustomDatetimeField
        source="lastLogin"
        label="Last Login"
        sortable={true}
      />
      <ChatNotificationButton />
      <DeleteButton size="medium" mutationMode="pessimistic" />
    </Datagrid>
  </List>
);
