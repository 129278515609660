import React from "react";
import { UserMenu, MenuItemLink, Logout, AppBar, Layout } from "react-admin";
import SettingsIcon from "@mui/icons-material//Settings";
import ArrowBackIcon from "@mui/icons-material//ArrowBack";
import Typography from "@mui/material/Typography";

import { getCustomerAssociations, hasScope, SCOPE } from "./auth";
import { isProduction, getEnvironmentName } from "./appConfig";
import { bwColours } from "./theme";
import { custInfo } from "./customer";

const styles = {
  logo: {
    height: "3em",
  },
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  env: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textAlign: "right",
  },
  spacer: {
    flex: 1,
  },
};

const Logo = () => {
  return <img src="/logo192.png" alt="BarrelWise" style={styles.logo} />;
};

const ChangePasswordItem = () => (
  <MenuItemLink
    to="/changepass"
    primaryText="Change Password"
    leftIcon={<SettingsIcon />}
  />
);

const ExitWinery = () => (
  <MenuItemLink to="/" primaryText="Exit Winery" leftIcon={<ArrowBackIcon />} />
);

const CustomUserMenu = ({ withExit }) => {
  const custs = getCustomerAssociations();
  return (
    <UserMenu>
      {withExit && (hasScope(SCOPE.ADMIN) || custs.length > 1) ? (
        <ExitWinery />
      ) : null}
      <ChangePasswordItem />
      <Logout />
    </UserMenu>
  );
};

const CustomAppBar = () => {
  const withExit = custInfo !== undefined;
  const defaultTitle = custInfo?.customerLongName;

  return (
    <AppBar
      userMenu={<CustomUserMenu withExit={withExit} />}
      sx={{
        "& .RaAppBar-title": {
          flex: 1,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        },
        background: isProduction() ? undefined : bwColours.primary.beige,
      }}
    >
      <Typography variant="h6" color="inherit" style={styles.title}>
        {defaultTitle ? defaultTitle + ": " : null}
        <span id="react-admin-title" />
      </Typography>
      <Logo />
      <Typography variant="h6" color="inherit" style={styles.env}>
        {isProduction() ? null : getEnvironmentName()}
      </Typography>
    </AppBar>
  );
};

const AppLayout = (props) => <Layout {...props} appBar={CustomAppBar} />;
export default AppLayout;
