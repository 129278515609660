import React from "react";
import { useRecordContext, useInput, Labeled } from "react-admin";
import PropTypes from "prop-types";
import Switch from "@mui/material/Switch";

const redWineColour = "#6E0519";
const whiteWineColour = "#D0B78C";

const circleStyle = {
  display: "flex",
  width: 20,
  height: 20,
  borderRadius: "50%",
};

const WineColourField = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  const isRed = record[source];

  if (isRed === true) {
    return <div style={{ ...circleStyle, backgroundColor: redWineColour }} />;
  } else if (isRed === false) {
    return <div style={{ ...circleStyle, backgroundColor: whiteWineColour }} />;
  } else {
    return null;
  }
};
WineColourField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};
WineColourField.defaultProps = {
  label: "Wine Color",
  addLabel: true,
};

const finiteSwitchStyles = {
  "& .MuiSwitch-switchBase": {
    color: whiteWineColour,
  },
  "&& .Mui-checked": {
    color: redWineColour,
  },
  "& .MuiSwitch-track": {
    backgroundColor: whiteWineColour,
  },
  "&& .Mui-checked+.MuiSwitch-track": {
    backgroundColor: redWineColour,
  },
};

const WineColorInput = (props) => {
  const {
    field: { name, onChange, checked, value, ...rest },
    isRequired,
  } = useInput(props);

  const common = {
    name: name,
    label: props.label,
    required: isRequired,
    value: value,
    ...rest,
  };

  if (value === false) {
    return (
      <Labeled label={props.label}>
        <Switch
          checked={false}
          sx={finiteSwitchStyles}
          onChange={() => {
            onChange(true);
          }}
          {...common}
        />
      </Labeled>
    );
  } else if (value === true) {
    return (
      <Labeled label={props.label}>
        <Switch
          checked={true}
          sx={finiteSwitchStyles}
          onChange={() => {
            onChange(null);
          }}
          {...common}
        />
      </Labeled>
    );
  } else {
    return (
      <Labeled label={props.label}>
        <Switch
          checked={false}
          sx={{
            "& .MuiSwitch-switchBase": {
              opacity: 0,
            },
            "& .MuiSwitch-track": {
              backgroundColor: "#999",
            },
          }}
          onChange={() => {
            onChange(false);
          }}
          {...common}
        />
      </Labeled>
    );
  }
};

export { WineColourField, WineColorInput };
