import { React, useState } from "react";

import {
  List,
  Datagrid,
  NumberField,
  TextField,
  TextInput,
  NumberInput,
  ReferenceInput,
  ArrayInput,
  SimpleForm,
  SimpleFormIterator,
  Create,
  Edit,
  DateTimeInput,
  minValue,
  number,
  Pagination,
  Show,
  AutocompleteInput,
  required,
  ReferenceField,
  TabbedShowLayout,
  Tab,
  EditButton,
  maxValue,
  useRecordContext,
  CreateButton,
  useListContext,
  Button,
  TopToolbar,
  FilterButton,
  ReferenceManyField,
  SelectInput,
  BooleanField,
} from "react-admin";

import Typography from "@mui/material/Typography";

// Lib
import { custInfo } from "../lib/customer";
import { hasScope, SCOPE } from "../lib/auth";
import { DetailsButton } from "../lib/detailsButton";
import { ContextTagField } from "../lib/contextTagField";
import { VolumeField } from "../lib/volume";
import { ChatBubbleTooltip } from "../lib/chatBubbleTooltip";
import { SectionHeader } from "../lib/sectionHeader";
import { CreateVCButton } from "../lib/createVCButton";
import {
  MeasureOrAddDetailsButton,
  MeasureOrAddField,
} from "../lib/measureOrAddField";
import {
  CustomDateField,
  CustomDatetimeField,
} from "../lib/customDatetimeField";
import { AddParameterIndicator } from "../lib/addParameterIndicator";
import { AliasOrBarcodeReference } from "../lib/aliasWithBarcodeTooltip";
import {
  CreateCSVDownloadQueryParams,
  CreateCSVDownloadURL,
} from "../lib/csvDownload";
import { OpenURL } from "../lib/csvDownload";
import RangePickerInput from "../lib/dateRangePicker";

// MUI
import IconDownload from "@mui/icons-material//CloudDownload";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconCancel from "@mui/icons-material//Cancel";
import DialogActions from "@mui/material/DialogActions";
import { CustomTab } from "../lib/customTab";

const autoCompleteCondition = (val) => !!val && val.trim().length >= 2;

const archivedFilterChoices = [
  { id: false, name: "Hide archived" },
  { id: true, name: "Show only archived" },
];

const lotFilters = [
  <TextInput source="search" label="Lot" alwaysOn resettable />,
  <SelectInput
    source="archived"
    label="Archived"
    alwaysOn
    choices={archivedFilterChoices}
    emptyText="Show all"
  />,
  <TextInput source="intendedUse" label="Intended Use" resettable />,
];

const LotAppBarTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span style={{ marginRight: "0.5em" }}> {"Lot " + record.name}</span>;
};

const LotExportButton = () => {
  const [showDialog, setShowDialog] = useState(false);
  const { sort, filterValues } = useListContext();

  const handleDownload = () => {
    setShowDialog(false);
    const queryParams = CreateCSVDownloadQueryParams(sort, filterValues);
    const csvDownloadURL = CreateCSVDownloadURL(
      custInfo.customerID,
      "lots",
      queryParams
    );
    console.log("Downloading " + csvDownloadURL);
    OpenURL(csvDownloadURL);
  };

  return (
    <>
      <Button
        onClick={() => {
          setShowDialog(true);
        }}
        label="Export"
      >
        <IconDownload />
      </Button>
      <Dialog fullWidth open={showDialog}>
        <DialogTitle>CSV Export</DialogTitle>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            variant="outlined"
            onClick={() => {
              setShowDialog(false);
            }}
          >
            <IconCancel />
          </Button>
          <Button label="Download" variant="contained" onClick={handleDownload}>
            <IconDownload />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const LotListActions = () => {
  return (
    <TopToolbar>
      <FilterButton />
      <CreateButton />
      <LotExportButton />
    </TopToolbar>
  );
};

const styles = {
  innovintName: {
    color: "#AAA",
    fontStyle: "italic",
    //fontSize: "90%",
  },
};

export const LotNameField = () => {
  // Shows the InnoVint Lot name in addition to our lot name
  const record = useRecordContext();

  return (
    <Typography variant="body2">
      <TextField source="name" />
      {record.custom?.innovintLot?.name ? (
        <span style={styles.innovintName}>
          {" "}
          {record.custom?.innovintLot?.name}
        </span>
      ) : null}
    </Typography>
  );
};

export const LotList = () => (
  <List
    perPage={25}
    pagination={<Pagination rowsPerPageOptions={[]} />}
    filters={lotFilters}
    filterDefaultValues={{ archived: false }}
    actions={<LotListActions />}
    sort={{ field: "id", order: "ASC" }}
    storeKey={false}
  >
    <Datagrid bulkActionButtons={false}>
      <ReferenceField label="Lot" source="id" reference="lots" link="show">
        <LotNameField />
      </ReferenceField>
      <CustomDateField source="date" label="Creation Date" />
      <ReferenceField
        label="Blend"
        source="blendID"
        reference="blends"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="intendedUse" label="Intended Use" />
      <NumberField source="addTarget" label={"Target Free SO\u2082 (ppm)"} />
      <NumberField source="barrelCount" label="Barrels" />
      <VolumeField source="volume" label="Volume" />
      <AddParameterIndicator />
      {hasScope(SCOPE.ADMIN) ? <CreateVCButton /> : null}
      <DetailsButton />
      {hasScope(SCOPE.ADMIN) || hasScope(SCOPE.MANAGER) ? <EditButton /> : null}
    </Datagrid>
  </List>
);

const validateTarget = [number(), minValue(0), maxValue(100)];
const validateMaxAdd = [number(), minValue(0), maxValue(100)];
const validateMinAdd = [number(), minValue(0), maxValue(100)];
// FIXME: the multiplication store the value in float point (e.g. 1.2) right now
// does RA wants to use this format or should it be in percentage e.g. 120%
const validateMultiplier = [number(), minValue(0), maxValue(10)];
const BarrelAssociationInput = () => {
  const getOptionText = (option) => {
    return option?.alias !== undefined && option?.alias !== ""
      ? `${option?.alias} (Barcode: ${option?.barcode})`
      : `${option?.barcode}`;
  };

  return (
    <ArrayInput source="barrelAssociations">
      <SimpleFormIterator TransitionProps={{ enter: false, exit: false }}>
        <ReferenceInput source="barrelID" reference="barrels" perPage={100}>
          <AutocompleteInput
            optionText={getOptionText}
            shouldRenderSuggestions={autoCompleteCondition}
            filterToQuery={(searchText) =>
              autoCompleteCondition(searchText)
                ? { search: searchText, simple: true }
                : { search: "", simple: true }
            }
            label="Barrel"
          />
        </ReferenceInput>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

const CreateEditForm = () => (
  <SimpleForm>
    <DateTimeInput label="date" source="date" defaultValue={new Date()} />
    <TextInput label="Lot" source="name" validate={required()} />
    <ReferenceInput
      source="blendID"
      reference="blends"
      validate={required()}
      sort={{ field: "name", order: "ASC" }}
    >
      <AutocompleteInput
        optionText="name"
        filterToQuery={(searchText) => ({ name: searchText })}
        label="Blend"
      />
    </ReferenceInput>
    <TextInput label="Intended Use" source="intendedUse" />
    <SectionHeader title="Additions Recommendations" />
    <NumberInput
      source="addTarget"
      label={"Target Free SO\u2082 (ppm)"}
      validate={validateTarget}
    />
    <NumberInput
      source="addMin"
      label={"Min. Free SO\u2082 Addition (ppm)"}
      validate={validateMinAdd}
    />
    <NumberInput
      source="addMax"
      label={"Max. Free SO\u2082 Addition (ppm)"}
      validate={validateMaxAdd}
    />
    <NumberInput
      source="addMultiplier"
      label={"Free SO\u2082 Multiplier"}
      validate={validateMultiplier}
    />
    {hasScope(SCOPE.ADMIN) ? <BarrelAssociationInput /> : null}
  </SimpleForm>
);

export const LotEdit = () => (
  <Edit title={<LotAppBarTitle />} redirect="list">
    <CreateEditForm />
  </Edit>
);

export const LotCreate = () => (
  <Create redirect="list">
    <CreateEditForm />
  </Create>
);

const LotMeasurementList = () => (
  <>
    <Datagrid bulkActionButtons={false}>
      <AliasOrBarcodeReference
        source="barrelID"
        reference="barrels"
        sortable={false}
      />
      <NumberField
        source="fso2"
        label={"Free SO\u2082 (ppm)"}
        link={false}
        sortable={false}
      />
      <CustomDatetimeField
        source="datetime"
        label="Measurement Time"
        sortable={false}
      />
      <DetailsButton />
    </Datagrid>
    <Pagination rowsPerPageOptions={[]} />
  </>
);

const LotBarrelsList = () => (
  <>
    <Datagrid bulkActionButtons={false}>
      <AliasOrBarcodeReference
        source="barrelID"
        reference="barrels"
        sortable={false}
      />
      <ReferenceField
        source="barrelID"
        reference="barrels"
        label="Volume (L)"
        link={false}
        sortable={false}
        textAlign="right"
      >
        <NumberField
          source="volume"
          options={{ minimumFractionDigits: 1, maximumFractionDigits: 1 }}
        />
      </ReferenceField>
      <CustomDatetimeField
        source="startTime"
        label="Date Added"
        sortable={false}
      />
      <CustomDatetimeField
        source="endTime"
        label="Date Removed"
        sortable={false}
      />
      <ReferenceField
        source="barrelID"
        reference="barrels"
        label={"Free SO\u2082 (ppm)"}
        link={false}
        textAlign="right"
        sortable={false}
      >
        <NumberField source="lastFSO2Read.fso2" />
      </ReferenceField>
      <ReferenceField
        source="barrelID"
        reference="barrels"
        label="Last Analyzed"
        link={false}
        sortable={false}
      >
        <CustomDatetimeField source="lastFSO2Read.datetime" />
      </ReferenceField>
    </Datagrid>
    <Pagination rowsPerPageOptions={[]} />
  </>
);

const LotVirtualCompositeList = () => (
  <>
    <Datagrid bulkActionButtons={false}>
      <NumberField
        source="eventFSO2ReadIDs.length"
        label="Barrels"
        sortable={false}
      />
      <NumberField
        source="fso2Avg"
        label={"Avg. Free SO\u2082 (ppm)"}
        sortable={false}
        options={{ minimumFractionDigits: 1, maximumFractionDigits: 1 }}
      />
      <ContextTagField source="context" sortable={false} />
      <CustomDatetimeField
        source="creationTime"
        label="Submission Time"
        sortable={false}
      />
      <ChatBubbleTooltip source="notes" label="Notes" sortable={false} />
      <DetailsButton />
    </Datagrid>
    <Pagination rowsPerPageOptions={[]} />
  </>
);

const LotSulfateAddList = () => (
  <>
    <Datagrid bulkActionButtons={false}>
      <AliasOrBarcodeReference
        source="barrelID"
        reference="barrels"
        sortable={false}
      />
      <NumberField source="addition" label="Addition (ppm)" sortable={false} />
      <CustomDatetimeField source="datetime" label="Time" />
      <ChatBubbleTooltip source="notes" sortable={false} />
    </Datagrid>
    <Pagination rowsPerPageOptions={[]} />
  </>
);

const LotLotSulfateAddList = () => (
  <>
    {" "}
    <Datagrid bulkActionButtons={false}>
      <NumberField label="Barrels" source="barrelTreatedCount" />
      <NumberField
        source="avgAddPerBarrel"
        label={"Avg. Add per Barrel (ppm)"}
        options={{ minimumFractionDigits: 1, maximumFractionDigits: 1 }}
      />
      <CustomDatetimeField source="timestamp" label="Time" />
      <DetailsButton />
    </Datagrid>
    <Pagination rowsPerPageOptions={[]} />
  </>
);

const LotEventListFilters = [
  <SelectInput
    label="Type"
    source="type"
    choices={[
      { id: "lot composite", name: "Lot Composite" },
      { id: "virtual composite", name: "Virtual Composite" },
      { id: "any composite", name: "Any Composite" },
      { id: "lot addition", name: "Lot Addition" },
    ]}
    emptyText={"All"}
  />,
  <RangePickerInput source="dateRange" label="Date" />,
];

const LotEventList = (props) => {
  const record = useRecordContext();
  return (
    <List
      perPage={25}
      pagination={<Pagination rowsPerPageOptions={[]} />}
      filter={{ lotID: record.id }}
      filters={LotEventListFilters}
      title={" "}
      storeKey={false}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="type" label="Event" sortable={false} />
        <MeasureOrAddField label="Free SO₂ (ppm)" sortable={false} />
        <ContextTagField source="type" sortable={false} />
        <CustomDatetimeField source="datetime" label="Time" sortable={false} />
        <MeasureOrAddDetailsButton />
      </Datagrid>
    </List>
  );
};

export const LotShow = (props) => (
  <Show title={<LotAppBarTitle />}>
    <TabbedShowLayout>
      <Tab label="Overview">
        <CustomDateField source="date" label="Creation Date" />
        <LotNameField label="Lot" />
        <ReferenceField
          source="blendID"
          reference="blends"
          label="Blend"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="addTarget" label={"Target Free SO\u2082 (ppm)"} />
        <NumberField
          source="addMin"
          label={"Min. Free SO\u2082 Addition (ppm)"}
        />
        <NumberField
          source="addMax"
          label={"Max. Free SO\u2082 Addition (ppm)"}
        />
        <NumberField
          source="addMultiplier"
          label={"Free SO\u2082 Multiplier"}
        />
        <TextField source="intendedUse" label="Intended Use" />
        <NumberField source="barrelCount" label="Barrels" />
        <VolumeField source="volume" label="Volume" />
        <BooleanField source="archived" label="Archived" />
      </Tab>
      <Tab label="Events">
        <ReferenceManyField reference="events" target="lotID" label="">
          <LotEventList />
        </ReferenceManyField>
      </Tab>
      <Tab label="Barrels">
        <ReferenceManyField reference="lot_barrel" target="lotID" label="">
          <LotBarrelsList />
        </ReferenceManyField>
      </Tab>
      <Tab label="Virtual Composites">
        <ReferenceManyField
          reference="virtual_composite"
          target="lotID"
          label=""
        >
          <LotVirtualCompositeList />
        </ReferenceManyField>
      </Tab>
      <Tab label="Measurements">
        <ReferenceManyField
          reference="events/fso2_reading"
          target="lotID"
          label=""
        >
          <LotMeasurementList />
        </ReferenceManyField>
      </Tab>
      <Tab label="Lot Additions">
        <ReferenceManyField
          reference="events/lot_addition"
          target="lotID"
          label=""
        >
          <LotLotSulfateAddList />
        </ReferenceManyField>
      </Tab>
      <Tab label="Additions">
        <ReferenceManyField
          reference="events/sulfate_addition"
          target="lotID"
          label=""
        >
          <LotSulfateAddList />
        </ReferenceManyField>
      </Tab>
      <CustomTab />
    </TabbedShowLayout>
  </Show>
);
