import React, { useState, useEffect } from "react";
// FIXME: Switch this to react-hook-form
import { Field, Form } from "react-final-form";
import {
  Button,
  CardActions,
  CircularProgress,
  TextField,
} from "@mui/material";
import { Alert } from "@mui/material";
import AuthPage from "./authPage";
import { auth } from "../lib/auth";

const styles = {
  form: {
    padding: "0 8px",
    textAlign: "center",
  },
  alert: {
    margin: "0 8px",
  },
  input: {
    marginTop: "0.5em",
    minHeight: "4em",
  },
  button: {
    width: "100%",
  },
  icon: {
    marginRight: "1em",
  },
};

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
    variant="standard"
  />
);

const ChangePasswordForm = (props) => {
  const { redirectTo } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const validate = (values) => {
    const errors = { email: undefined, password: undefined };

    if (!values.password1) {
      errors.password1 = "Required";
    } else if (values.password1.length < 8) {
      errors.password1 = "At least 8 characters";
    }
    if (!values.password2) {
      errors.password2 = "Required";
    } else if (values.password1 !== values.password2) {
      errors.password2 = "Does not match";
    }
    return errors;
  };

  const submit = async ({ password1 }) => {
    ("submit");
    setLoading(true);
    setError("");

    let { error, user } = await auth.update({ password: password1 });
    if (user) {
      if (redirectTo) {
        window.location.assign(redirectTo);
      } else {
        window.location.assign("/");
      }
    } else {
      setLoading(false);
      if (error && error.message) setError(error.message);
      else {
        setError("Server error. Try again later.");
      }
    }
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div style={styles.form}>
            <h3>Change Password</h3>
            <div style={styles.input}>
              <Field
                id="password1"
                name="password1"
                component={Input}
                label="Password"
                type="password"
                disabled={loading}
                autoComplete="new-password"
              />
            </div>
            <div style={styles.input}>
              <Field
                id="password2"
                name="password2"
                component={Input}
                label="Password (Again)"
                type="password"
                disabled={loading}
                autoComplete="new-password"
              />
            </div>
          </div>
          <Alert
            severity="error"
            style={{
              ...styles.alert,
              visibility: error ? "visible" : "hidden",
            }}
          >
            {error}
          </Alert>
          <CardActions>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              style={styles.button}
            >
              {loading && (
                <CircularProgress style={styles.icon} size={18} thickness={2} />
              )}
              Continue
            </Button>
          </CardActions>
          <Button
            onClick={() => {
              window.location.replace("/");
            }}
          >
            Cancel
          </Button>
        </form>
      )}
    />
  );
};

export const RedeemToken = ({ type, onSuccess, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const params = new URL(document.location).searchParams;

  const changePassword = () => {
    setLoading(true);
    auth
      .verifyOTP({ type: type, token: params.get("token") })
      .then((resp) => {
        if (resp.error) {
          setError(resp.error.message || "Unknown error");
          setLoading(false);
        } else {
          onSuccess(true);
        }
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  if (error) {
    return (
      <div>
        <Alert
          severity="error"
          style={{
            ...styles.alert,
            visibility: error ? "visible" : "hidden",
            marginBottom: "5em",
            textAlign: "left",
          }}
        >
          <b>The link is invalid or expired.</b>
          <br />
          Please try again or contact
          <br />
          <a href="mailto:support@barrelwise.ca">support@barrelwise.ca</a>.
          <br />
          <br />
          <pre style={{ fontSize: 11 }}>Error: {error}</pre>
        </Alert>
        <Button
          onClick={() => {
            window.location.replace("/");
          }}
        >
          Cancel
        </Button>
      </div>
    );
  } else if (loading) {
    return (
      <div style={{ marginBottom: "4em" }}>
        <CircularProgress size={25} thickness={2} />
      </div>
    );
  } else {
    if (type === "recovery") {
      return (
        <div style={{ marginBottom: "4em" }}>
          <Alert
            severity="success"
            style={{
              ...styles.alert,
              marginBottom: "5em",
              textAlign: "left",
            }}
          >
            <b>Your account has been recovered.</b>
            <br />
            <br />
            Please click below to choose a password.
            <br />
          </Alert>
          <Button
            variant="contained"
            color="primary"
            style={styles.button}
            onClick={changePassword}
          >
            Change Password
          </Button>
        </div>
      );
    } else {
      return (
        <div style={{ marginBottom: "4em" }}>
          <Alert
            severity="success"
            style={{
              ...styles.alert,
              marginBottom: "5em",
              textAlign: "left",
            }}
          >
            <b>Your account has been created.</b>
            <br />
            Welcome to BarrelWise!
            <br />
            <br />
            Please click below to choose a password.
            <br />
          </Alert>
          <Button
            variant="contained"
            color="primary"
            style={styles.button}
            onClick={changePassword}
          >
            Change Password
          </Button>
        </div>
      );
    }
  }
};

const ChangePass = ({ fromFlow, ...rest }) => {
  // If we are not coming from a flow (password reset, invite link, etc.),
  // then there is no token validation necessary
  const [validated, setValidated] = useState(fromFlow === undefined);

  useEffect(() => {
    document.title = "BarrelWise - Change Password";
  }, []);

  return (
    <AuthPage>
      {validated ? (
        <ChangePasswordForm {...rest} />
      ) : (
        <RedeemToken onSuccess={setValidated} type={fromFlow} {...rest} />
      )}
    </AuthPage>
  );
};

export default ChangePass;
