// in src/Dashboard.js
import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Title } from "react-admin";
import ScatterChart from "./charts/scatter";

const DashboardCard = () => (
  <Card>
    <Title title="Dashboard" />
    <CardContent>
      <ScatterChart />
    </CardContent>
  </Card>
);

export default DashboardCard;
