import React from "react";

import {
  List,
  Datagrid,
  NumberField,
  TextField,
  TextInput,
  Edit,
  SimpleForm,
  EditButton,
  Create,
} from "react-admin";

export const MetaDataList = () => (
  <List
    pagination={null}
    sort={{ field: "name", order: "ASC" }}
    storeKey={false}
  >
    <Datagrid rowClick={null} bulkActionButtons={false}>
      <NumberField source="id" label="ID" />
      <TextField source="name" label="Name" />
      <EditButton />
    </Datagrid>
  </List>
);

export const MetaDataEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="name" label="Name" />
    </SimpleForm>
  </Edit>
);

export const MetaDataCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="name" label="Name" />
    </SimpleForm>
  </Create>
);
