import React, { useEffect } from "react";
import { Admin, Resource } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";

import RouterIcon from "@mui/icons-material//Router";
import BusinessIcon from "@mui/icons-material//Business";
import PersonIcon from "@mui/icons-material//Person";
import ArchiveIcon from "@mui/icons-material//Archive";
import PersonPin from "@mui/icons-material//PersonPin";
import Whatshot from "@mui/icons-material//Whatshot";
import NatureIcon from "@mui/icons-material//Nature";
import EqualizerIcon from "@mui/icons-material//Equalizer";
import InfoIcon from "@mui/icons-material//Info";
import ScatterPlotIcon from "@mui/icons-material//ScatterPlot";
import PolylineIcon from "@mui/icons-material/Polyline";

import { fetchJson } from "./lib/httpClient";
import { authProvider, setScopesCustomerID } from "./lib/auth";
import { theme } from "./lib/theme";
import AppLayout from "./lib/appLayout";
import { setCustInfo } from "./lib/customer";

import { DeviceList, DeviceEdit, DeviceCreate } from "./resources/device";
import {
  MetaDataList,
  MetaDataEdit,
  MetaDataCreate,
} from "./resources/metadata";

import {
  CustomerList,
  CustomerEdit,
  CustomerCreate,
} from "./resources/customer";
import { FirmwareList } from "./resources/firmware";
import { UserList, UserEdit, UserCreate } from "./resources/user";
import {
  VarietalList,
  VarietalEdit,
  VarietalCreate,
} from "./resources/varietals";
import { TaskList, TaskCreateAdmin, TaskShow } from "./resources/tasks";

const dataProvider = simpleRestProvider("/api/v2", fetchJson);

export const AdminApp = () => {
  useEffect(() => {
    document.title = "BarrelWise - Admin";
  }, []);

  // Unset some global state used by app-customer.js
  setCustInfo(undefined);
  setScopesCustomerID(undefined);

  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      layout={AppLayout}
      theme={theme}
      loginPage={() => null}
    >
      <Resource
        name="customers"
        list={CustomerList}
        create={CustomerCreate}
        edit={CustomerEdit}
        icon={BusinessIcon}
      />
      <Resource
        name="users"
        list={UserList}
        create={UserCreate}
        edit={UserEdit}
        icon={PersonIcon}
      />
      <Resource
        name="devices"
        list={DeviceList}
        create={DeviceCreate}
        edit={DeviceEdit}
        icon={RouterIcon}
      />
      <Resource name="firmware" list={FirmwareList} icon={ArchiveIcon} />
      <Resource
        name="barrel_cooper"
        list={MetaDataList}
        create={MetaDataCreate}
        edit={MetaDataEdit}
        icon={PersonPin}
      />
      <Resource
        name="barrel_toast"
        list={MetaDataList}
        create={MetaDataCreate}
        edit={MetaDataEdit}
        icon={Whatshot}
      />
      <Resource
        name="barrel_oak"
        list={MetaDataList}
        create={MetaDataCreate}
        edit={MetaDataEdit}
        icon={InfoIcon}
      />
      <Resource
        name="barrel_forest"
        list={MetaDataList}
        create={MetaDataCreate}
        edit={MetaDataEdit}
        icon={NatureIcon}
      />
      <Resource
        name="barrel_type"
        list={MetaDataList}
        create={MetaDataCreate}
        edit={MetaDataEdit}
        icon={EqualizerIcon}
      />
      <Resource
        name="varietals"
        list={VarietalList}
        create={VarietalCreate}
        edit={VarietalEdit}
        icon={ScatterPlotIcon}
      />
      <Resource
        name="tasks"
        options={{ label: "Tasks" }}
        list={TaskList}
        create={TaskCreateAdmin}
        show={TaskShow}
        icon={PolylineIcon}
      />
    </Admin>
  );
};
